import React from "react";

function Languages({ language }) {
	if (language === "CN") {
		return (
			<svg width="40" height="30" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<mask id="mask0_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="30">
					<path
						d="M29.25 18.3178C19.609 16.2672 9.64728 16.2473 0 18.2581C0 12.6699 0 7.08172 0 1.49343C9.64736 -0.517474 19.609 -0.49763 29.25 1.55305C27.7539 4.0289 26.25 6.55373 24.7408 9.12738C26.25 12.142 27.7539 15.2055 29.25 18.3178Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_26884_54567)">
					<mask id="mask1_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="31" height="21">
						<path d="M-1 1.13705C9.19654 -1.1422 19.7705 -1.1422 29.9671 1.13705C29.9671 7.1573 29.9671 13.1776 29.9671 19.1979C19.7706 16.9186 9.19662 16.9186 -1 19.1979C-1 13.1775 -1 7.1573 -1 1.13705Z" fill="white" />
					</mask>
					<g mask="url(#mask1_26884_54567)">
						<path d="M-1 19.1979C9.19654 16.9186 19.7705 16.9186 29.9671 19.1979C29.9671 13.1776 29.9671 7.1573 29.9671 1.13705C19.7706 -1.1422 9.19662 -1.1422 -1 1.13705C-1 7.1573 -1 13.1775 -1 19.1979Z" fill="#CB2128" />
					</g>
					<mask id="mask2_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="31" height="21">
						<path d="M-1 1.13705C9.19654 -1.1422 19.7705 -1.1422 29.9671 1.13705C29.9671 7.1573 29.9671 13.1776 29.9671 19.1979C19.7706 16.9186 9.19662 16.9186 -1 19.1979C-1 13.1775 -1 7.1573 -1 1.13705Z" fill="white" />
					</mask>
					<g mask="url(#mask2_26884_54567)">
						<path
							d="M1.95216 7.08093C2.14431 6.44577 2.33664 5.81127 2.52896 5.1777C2.02257 4.89018 1.51669 4.60826 1.01172 4.33179C1.63801 4.21022 2.26572 4.09727 2.89427 3.99277C3.09061 3.36012 3.28696 2.72839 3.48355 2.09741C3.67855 2.66702 3.87364 3.23729 4.0689 3.8084C4.69937 3.7168 5.33068 3.63383 5.96292 3.55939C5.45226 3.99369 4.94235 4.43344 4.43295 4.87887C4.62594 5.45199 4.81894 6.02595 5.01218 6.60067C4.50127 6.29782 3.99103 6.00042 3.48146 5.70871C2.97097 6.1605 2.46114 6.61791 1.95216 7.08093Z"
							fill="#F6DD25"
						/>
					</g>
					<mask id="mask3_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="31" height="21">
						<path d="M-1 1.13705C9.19654 -1.1422 19.7705 -1.1422 29.9671 1.13705C29.9671 7.1573 29.9671 13.1776 29.9671 19.1979C19.7706 16.9186 9.19662 16.9186 -1 19.1979C-1 13.1775 -1 7.1573 -1 1.13705Z" fill="white" />
					</mask>
					<g mask="url(#mask3_26884_54567)">
						<path
							d="M7.22339 7.31107C7.43773 7.37563 7.65208 7.4411 7.86667 7.50758C8.01496 7.31735 8.16341 7.12754 8.31178 6.93823C8.2957 7.16874 8.27962 7.39932 8.26355 7.62983C8.47747 7.69757 8.69123 7.76622 8.90524 7.8358C8.68144 7.91024 8.45763 7.98576 8.23382 8.06237C8.21708 8.29061 8.20033 8.51902 8.1835 8.74727C8.06101 8.56457 7.9386 8.3823 7.81619 8.20027C7.59338 8.27538 7.37067 8.35157 7.14795 8.42877C7.29464 8.2382 7.44133 8.04805 7.58803 7.85841C7.46645 7.67563 7.34496 7.49318 7.22339 7.31107Z"
							fill="#F6DD25"
						/>
					</g>
					<mask id="mask4_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="31" height="21">
						<path d="M-1 1.13705C9.19654 -1.1422 19.7705 -1.1422 29.9671 1.13705C29.9671 7.1573 29.9671 13.1776 29.9671 19.1979C19.7706 16.9186 9.19662 16.9186 -1 19.1979C-1 13.1775 -1 7.1573 -1 1.13705Z" fill="white" />
					</mask>
					<g mask="url(#mask4_26884_54567)">
						<path
							d="M10.6994 3.57482C10.4714 3.54769 10.2435 3.52165 10.0156 3.4967C9.90654 3.70686 9.79753 3.91718 9.68843 4.12776C9.65661 3.90236 9.62471 3.67688 9.59281 3.45157C9.36507 3.4257 9.13733 3.401 8.90967 3.3773C9.11681 3.26134 9.32387 3.14621 9.5311 3.03201C9.49979 2.80586 9.46839 2.5797 9.43699 2.35364C9.59775 2.50703 9.75859 2.66101 9.91943 2.81557C10.1266 2.70228 10.3338 2.58984 10.5411 2.47839C10.433 2.68763 10.3249 2.89712 10.2168 3.10686C10.3776 3.26226 10.5385 3.41825 10.6994 3.57482Z"
							fill="#F6DD25"
						/>
					</g>
					<mask id="mask5_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="31" height="21">
						<path d="M-1 1.13705C9.19654 -1.1422 19.7705 -1.1422 29.9671 1.13705C29.9671 7.1573 29.9671 13.1776 29.9671 19.1979C19.7706 16.9186 9.19662 16.9186 -1 19.1979C-1 13.1775 -1 7.1573 -1 1.13705Z" fill="white" />
					</mask>
					<g mask="url(#mask5_26884_54567)">
						<path
							d="M9.24989 6.75283C9.32072 6.52919 9.39147 6.30564 9.46231 6.08216C9.27576 5.95967 9.0893 5.83793 8.90283 5.71694C9.13426 5.69869 9.36577 5.68152 9.59753 5.66562C9.66836 5.44198 9.73911 5.2185 9.81003 4.99512C9.88162 5.20913 9.95329 5.42314 10.0249 5.6374C10.2566 5.62275 10.4882 5.60935 10.7202 5.59704C10.5333 5.74273 10.3462 5.88909 10.1594 6.03628C10.2296 6.25046 10.2996 6.46464 10.3698 6.67906C10.1831 6.55565 9.99658 6.43307 9.81003 6.31108C9.62332 6.45752 9.4366 6.60471 9.24989 6.75283Z"
							fill="#F6DD25"
						/>
					</g>
					<mask id="mask6_26884_54567" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="31" height="21">
						<path d="M-1 1.13705C9.19654 -1.1422 19.7705 -1.1422 29.9671 1.13705C29.9671 7.1573 29.9671 13.1776 29.9671 19.1979C19.7706 16.9186 9.19662 16.9186 -1 19.1979C-1 13.1775 -1 7.1573 -1 1.13705Z" fill="white" />
					</mask>
					<g mask="url(#mask6_26884_54567)">
						<path
							d="M7.25912 0.941657C7.4676 1.01777 7.67609 1.09488 7.88482 1.17283C8.04206 0.99022 8.19939 0.808194 8.35663 0.626587C8.32942 0.857259 8.30221 1.08802 8.27491 1.31877C8.48331 1.39873 8.6918 1.47953 8.90045 1.56125C8.67438 1.62271 8.44832 1.68525 8.22225 1.74888C8.19403 1.97922 8.16582 2.20964 8.1376 2.43998C8.02498 2.24941 7.91254 2.05901 7.80009 1.86903C7.57419 1.9325 7.34829 1.99705 7.12256 2.06278C7.27913 1.87841 7.4357 1.69471 7.59244 1.51151C7.48134 1.3212 7.37023 1.1313 7.25912 0.941657Z"
							fill="#F6DD25"
						/>
					</g>
				</g>
			</svg>
		);
	}

	if (language === "JP") {
		return (
			<svg width="40" height="30" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<mask id="mask0_26884_14542" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="30">
					<path
						d="M29.761 18.6843C19.9577 16.5359 9.80722 16.5236 0 18.6479C0 12.96 0 7.27204 0 1.58405C9.80722 -0.540119 19.9577 -0.527932 29.761 1.62052C28.2396 4.13109 26.71 6.69304 25.1745 9.30655C26.71 12.3811 28.2398 15.507 29.761 18.6843Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_26884_14542)">
					<mask id="mask1_26884_14542" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.45166 1.29996C9.59026 -0.944713 20.0038 -0.944713 30.0457 1.29996C30.0457 7.22922 30.0457 13.1585 30.0457 19.0878C20.0038 16.8431 9.59026 16.8431 -0.45166 19.0878C-0.45166 13.1586 -0.45166 7.2293 -0.45166 1.29996Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask1_26884_14542)">
						<path
							d="M-0.45166 19.0878C9.59026 16.8431 20.0038 16.8431 30.0457 19.0878C30.0457 13.1586 30.0457 7.2293 30.0457 1.29996C20.0038 -0.944713 9.59026 -0.944713 -0.45166 1.29996C-0.45166 7.2293 -0.45166 13.1586 -0.45166 19.0878Z"
							fill="white"
						/>
					</g>
					<mask id="mask2_26884_14542" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.45166 1.29996C9.59026 -0.944713 20.0038 -0.944713 30.0457 1.29996C30.0457 7.22922 30.0457 13.1585 30.0457 19.0878C20.0038 16.8431 9.59026 16.8431 -0.45166 19.0878C-0.45166 13.1586 -0.45166 7.2293 -0.45166 1.29996Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask2_26884_14542)">
						<mask id="mask3_26884_14542" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
							<path
								d="M-0.45166 1.29996C9.59026 -0.944713 20.0038 -0.944713 30.0457 1.29996C30.0457 7.22922 30.0457 13.1585 30.0457 19.0878C20.0038 16.8431 9.59026 16.8431 -0.45166 19.0878C-0.45166 13.1586 -0.45166 7.2293 -0.45166 1.29996Z"
								fill="white"
							/>
						</mask>
						<g mask="url(#mask3_26884_14542)">
							<path d="M14.7983 3.17481C17.7652 3.17148 20.162 5.77358 20.1618 8.71647C20.1623 11.6658 17.7653 13.8492 14.7983 13.8526C11.8314 13.8489 9.43081 11.6661 9.43115 8.71656C9.4309 5.77358 11.8312 3.17131 14.7983 3.17481Z" fill="#AC1F24" />
						</g>
					</g>
				</g>
			</svg>
		);
	}

	if (language === "KR") {
		return (
			<svg width="40" height="30" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<mask id="mask0_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="30">
					<path
						d="M29.761 18.6653C19.9557 16.5294 9.80517 16.5297 0 18.6666C0 12.9787 0 7.29093 0 1.60294C9.80517 -0.533929 19.9557 -0.534269 29.761 1.60166C28.2394 4.1141 26.7094 6.67801 25.1737 9.2934C26.7094 12.366 28.2394 15.4899 29.761 18.6653Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_26884_14358)">
					<mask id="mask1_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask1_26884_14358)">
						<path
							d="M-0.364258 19.0773C9.67689 16.8328 20.0897 16.8328 30.1308 19.0773C30.1308 13.148 30.1308 7.21873 30.1308 1.28939C20.0897 -0.955025 9.67689 -0.955025 -0.364258 1.28939C-0.364258 7.21873 -0.364258 13.148 -0.364258 19.0773Z"
							fill="white"
						/>
					</g>
					<mask id="mask2_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask2_26884_14358)">
						<path d="M19.8445 2.71338C20.2223 3.30559 20.6001 3.90104 20.9775 4.49955C20.7719 4.61886 20.5664 4.7392 20.3607 4.86038C19.9825 4.26536 19.6041 3.67324 19.2256 3.08435C19.432 2.95984 19.6382 2.8361 19.8445 2.71338Z" fill="#010101" />
					</g>
					<mask id="mask3_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask3_26884_14358)">
						<path d="M21.1841 4.82666C21.5628 5.42663 21.9412 6.02966 22.3195 6.63584C22.1134 6.7497 21.9072 6.86458 21.7009 6.98023C21.3228 6.37873 20.9445 5.78038 20.5659 5.1851C20.7721 5.06469 20.9781 4.9452 21.1841 4.82666Z" fill="#010101" />
					</g>
					<mask id="mask4_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask4_26884_14358)">
						<path d="M20.7746 2.16797C21.5996 3.47179 22.4238 4.79035 23.2465 6.12365C23.0405 6.2358 22.8344 6.34889 22.6282 6.46275C21.8046 5.13703 20.9796 3.82614 20.1538 2.52982C20.3607 2.4083 20.5677 2.28762 20.7746 2.16797Z" fill="#010101" />
					</g>
					<mask id="mask5_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask5_26884_14358)">
						<path d="M21.7009 1.63562C22.0788 2.23899 22.4566 2.84569 22.8341 3.45537C22.6281 3.56761 22.4219 3.68096 22.2158 3.79515C21.8377 3.18982 21.4595 2.58764 21.0811 1.98853C21.2877 1.86998 21.4944 1.75229 21.7009 1.63562Z" fill="#010101" />
					</g>
					<mask id="mask6_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask6_26884_14358)">
						<path d="M23.0392 3.78711C23.416 4.39739 23.7924 5.01065 24.1686 5.62706C23.9635 5.73657 23.7582 5.84676 23.5529 5.95798C23.1762 5.34549 22.7992 4.73606 22.4219 4.1297C22.6278 4.01448 22.8335 3.90028 23.0392 3.78711Z" fill="#010101" />
					</g>
					<mask id="mask7_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask7_26884_14358)">
						<path d="M8.06617 10.6816C8.89061 11.8337 9.71616 13.0005 10.5424 14.1818C10.3358 14.3318 10.1292 14.4827 9.92273 14.6346C9.0971 13.4608 8.27232 12.3015 7.44873 11.1571C7.65446 10.9978 7.86027 10.8393 8.06617 10.6816Z" fill="#010101" />
					</g>
					<mask id="mask8_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask8_26884_14358)">
						<path d="M7.13973 11.3979C7.51649 11.9196 7.8936 12.4443 8.27088 12.9721C8.06533 13.1294 7.85977 13.2875 7.65438 13.4466C7.27685 12.9215 6.89957 12.3993 6.52246 11.8803C6.7281 11.7186 6.93391 11.5578 7.13973 11.3979Z" fill="#010101" />
					</g>
					<mask id="mask9_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask9_26884_14358)">
						<path d="M8.47896 13.2666C8.85658 13.7975 9.23445 14.3312 9.6125 14.8681C9.40635 15.0209 9.20011 15.1745 8.99404 15.3291C8.61608 14.7956 8.23829 14.2652 7.86084 13.738C8.06682 13.58 8.27289 13.4228 8.47896 13.2666Z" fill="#010101" />
					</g>
					<mask id="mask10_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask10_26884_14358)">
						<path d="M6.21454 12.1234C7.03643 13.2538 7.85967 14.3988 8.68386 15.5585C8.47788 15.7139 8.27189 15.8704 8.066 16.0277C7.24173 14.8757 6.41839 13.7384 5.59668 12.6158C5.80249 12.4507 6.00847 12.2865 6.21454 12.1234Z" fill="#010101" />
					</g>
					<mask id="mask11_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask11_26884_14358)">
						<path d="M7.44873 6.63557C8.27232 5.31343 9.09719 4.00612 9.92273 2.71338C10.1292 2.83601 10.3358 2.95976 10.5424 3.08435C9.71616 4.36823 8.89061 5.66693 8.06617 6.98021C7.86027 6.8643 7.65446 6.74951 7.44873 6.63557Z" fill="#010101" />
					</g>
					<mask id="mask12_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask12_26884_14358)">
						<path d="M6.52246 6.1234C7.3452 4.7901 8.16921 3.47171 8.99416 2.16772C9.20023 2.28755 9.40638 2.40805 9.61262 2.52984C8.78733 3.82607 7.96289 5.13704 7.13973 6.46259C6.93391 6.34865 6.7281 6.23556 6.52246 6.1234Z" fill="#010101" />
					</g>
					<mask id="mask13_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask13_26884_14358)">
						<path d="M5.59668 5.62743C6.41848 4.2822 7.24164 2.95162 8.066 1.63562C8.27189 1.75229 8.47788 1.86998 8.68386 1.9887C7.85967 3.29711 7.03643 4.62019 6.21454 5.95801C6.00847 5.84688 5.80249 5.73669 5.59668 5.62743Z" fill="#010101" />
					</g>
					<mask id="mask14_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask14_26884_14358)">
						<path d="M19.2256 14.182C19.6041 13.6413 19.9825 13.1037 20.3607 12.5692C20.5664 12.7228 20.772 12.8773 20.9775 13.0327C20.6 13.5636 20.2223 14.0976 19.8445 14.6347C19.6381 14.4829 19.4319 14.3319 19.2256 14.182Z" fill="#010101" />
					</g>
					<mask id="mask15_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask15_26884_14358)">
						<path d="M20.5659 12.2808C20.9445 11.7446 21.3228 11.2116 21.7009 10.6816C21.9071 10.8393 22.1134 10.9979 22.3195 11.1574C21.9413 11.6826 21.5628 12.2109 21.1841 12.7423C20.9781 12.5876 20.7721 12.4337 20.5659 12.2808Z" fill="#010101" />
					</g>
					<mask id="mask16_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask16_26884_14358)">
						<path d="M20.1538 14.868C20.5321 14.3311 20.9102 13.7974 21.2881 13.2666C21.4946 13.4229 21.701 13.58 21.9073 13.738C21.53 14.2653 21.1524 14.7957 20.7746 15.3292C20.5677 15.1746 20.3607 15.0208 20.1538 14.868Z" fill="#010101" />
					</g>
					<mask id="mask17_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask17_26884_14358)">
						<path d="M21.4941 12.9718C21.8724 12.4441 22.2503 11.9194 22.6279 11.3979C22.8341 11.5578 23.0402 11.7186 23.2462 11.8804C22.8689 12.3994 22.4914 12.9215 22.1135 13.4466C21.9071 13.2875 21.7007 13.1292 21.4941 12.9718Z" fill="#010101" />
					</g>
					<mask id="mask18_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask18_26884_14358)">
						<path d="M21.0811 15.5583C21.4595 15.0273 21.8377 14.4993 22.2158 13.9745C22.4219 14.1326 22.628 14.2918 22.8341 14.4518C22.4565 14.9741 22.0788 15.4992 21.7009 16.0277C21.4943 15.8702 21.2876 15.7137 21.0811 15.5583Z" fill="#010101" />
					</g>
					<mask id="mask19_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask19_26884_14358)">
						<path d="M22.4219 13.6885C22.7992 13.1638 23.1763 12.6422 23.5529 12.1235C23.7582 12.2867 23.9635 12.4508 24.1686 12.6155C23.7924 13.1293 23.4159 13.6462 23.0392 14.166C22.8335 14.0059 22.6278 13.8467 22.4219 13.6885Z" fill="#010101" />
					</g>
					<mask id="mask20_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
						<path
							d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask20_26884_14358)">
						<mask id="mask21_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
							<path
								d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
								fill="white"
							/>
						</mask>
						<g mask="url(#mask21_26884_14358)">
							<path d="M11.1622 6.13147C11.1453 6.15474 11.129 6.18056 11.1147 6.20979C11.1314 6.18005 11.1453 6.15482 11.1622 6.13147Z" fill="#B51F3C" />
						</g>
						<mask id="mask22_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
							<path
								d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
								fill="white"
							/>
						</mask>
						<g mask="url(#mask22_26884_14358)">
							<path
								d="M17.3662 4.84174C15.3097 3.39236 12.5308 4.00537 11.1623 6.13141C10.4755 7.192 10.7546 8.55385 11.7824 9.18629C12.8092 9.81932 14.1972 9.51831 14.8827 8.49999C15.5687 7.47485 16.9575 7.22413 17.9842 7.95039C19.0132 8.68135 19.2894 10.0865 18.6036 11.0666C19.973 9.10967 19.42 6.29052 17.3662 4.84174Z"
								fill="#B51F3C"
							/>
						</g>
						<mask id="mask23_26884_14358" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
							<path
								d="M-0.364258 1.28939C9.67689 -0.955025 20.0897 -0.955025 30.1308 1.28939C30.1308 7.21865 30.1308 13.1479 30.1308 19.0773C20.0897 16.8328 9.67689 16.8328 -0.364258 19.0773C-0.364258 13.148 -0.364258 7.21873 -0.364258 1.28939Z"
								fill="white"
							/>
						</mask>
						<g mask="url(#mask23_26884_14358)">
							<path
								d="M11.1624 6.13147C10.4755 7.19206 10.7547 8.55392 11.7824 9.18635C12.8092 9.81938 14.1972 9.51838 14.8827 8.50005C15.5687 7.47491 16.9575 7.22419 17.9842 7.95046C19.0132 8.68141 19.2894 10.0866 18.6036 11.0667C17.236 13.0291 14.4574 13.5205 12.4017 12.2463C10.348 10.969 9.79301 8.26399 11.1624 6.13147Z"
								fill="#273875"
							/>
						</g>
					</g>
				</g>
			</svg>
		);
	}

	return (
		<svg width="40" height="30" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="19">
				<path d="M30 18.9483C20.1131 16.7938 9.88136 16.8112 0 19C0 13.2182 0 7.43645 0 1.65467C9.88119 -0.53418 20.1132 -0.551419 30 1.60295C28.4658 4.15945 26.9232 6.76837 25.3749 9.42951C26.9232 12.5501 28.4658 15.723 30 18.9483Z" fill="white" />
			</mask>
			<g mask="url(#mask0_26884_6777)">
				<mask id="mask1_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask1_26884_6777)">
					<path
						d="M-0.25293 19.2523C9.86923 16.9708 20.3662 16.9708 30.4883 19.2523C30.4883 18.7888 30.4883 18.3252 30.4883 17.8617C20.3662 15.5801 9.86923 15.5801 -0.25293 17.8617C-0.25293 18.3252 -0.25293 18.7887 -0.25293 19.2523Z"
						fill="#CB2128"
					/>
				</g>
				<mask id="mask2_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask2_26884_6777)">
					<path d="M-0.25293 17.8616C9.86923 15.5801 20.3662 15.5801 30.4883 17.8616C30.4883 17.3981 30.4883 16.9346 30.4883 16.471C20.3662 14.1895 9.86923 14.1895 -0.25293 16.471C-0.25293 16.9346 -0.25293 17.3981 -0.25293 17.8616Z" fill="white" />
				</g>
				<mask id="mask3_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask3_26884_6777)">
					<path
						d="M-0.25293 16.4711C9.86923 14.1895 20.3662 14.1895 30.4883 16.4711C30.4883 16.0078 30.4883 15.5445 30.4883 15.0813C20.3662 12.7997 9.86923 12.7997 -0.25293 15.0813C-0.25293 15.5445 -0.25293 16.0078 -0.25293 16.4711Z"
						fill="#CB2128"
					/>
				</g>
				<mask id="mask4_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask4_26884_6777)">
					<path d="M-0.25293 15.0812C9.86923 12.7997 20.3662 12.7997 30.4883 15.0812C30.4883 14.6173 30.4883 14.1534 30.4883 13.6895C20.3662 11.408 9.86923 11.408 -0.25293 13.6895C-0.25293 14.1534 -0.25293 14.6173 -0.25293 15.0812Z" fill="white" />
				</g>
				<mask id="mask5_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask5_26884_6777)">
					<path d="M-0.25293 13.6895C9.86923 11.408 20.3662 11.408 30.4883 13.6895C30.4883 13.2256 30.4883 12.7617 30.4883 12.2978C20.3662 10.0163 9.86923 10.0163 -0.25293 12.2978C-0.25293 12.7617 -0.25293 13.2256 -0.25293 13.6895Z" fill="#CB2128" />
				</g>
				<mask id="mask6_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask6_26884_6777)">
					<path d="M-0.25293 12.2977C9.86923 10.0162 20.3662 10.0162 30.4883 12.2977C30.4883 11.8346 30.4883 11.3714 30.4883 10.9083C20.3662 8.62676 9.86923 8.62676 -0.25293 10.9083C-0.25293 11.3714 -0.25293 11.8346 -0.25293 12.2977Z" fill="white" />
				</g>
				<mask id="mask7_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask7_26884_6777)">
					<path d="M-0.25293 10.9084C9.86923 8.62688 20.3662 8.62688 30.4883 10.9084C30.4883 10.4442 30.4883 9.97984 30.4883 9.5156C20.3662 7.23406 9.86923 7.23406 -0.25293 9.5156C-0.25293 9.97984 -0.25293 10.4442 -0.25293 10.9084Z" fill="#CB2128" />
				</g>
				<mask id="mask8_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask8_26884_6777)">
					<path d="M-0.25293 9.51561C9.86923 7.23407 20.3662 7.23407 30.4883 9.51561C30.4883 9.05197 30.4883 8.58825 30.4883 8.12461C20.3662 5.84307 9.86923 5.84307 -0.25293 8.12461C-0.25293 8.58825 -0.25293 9.05197 -0.25293 9.51561Z" fill="white" />
				</g>
				<mask id="mask9_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask9_26884_6777)">
					<path d="M-0.25293 8.12452C9.86923 5.84298 20.3662 5.84298 30.4883 8.12452C30.4883 7.6614 30.4883 7.19829 30.4883 6.73508C20.3662 4.45354 9.86923 4.45354 -0.25293 6.73508C-0.25293 7.19829 -0.25293 7.6614 -0.25293 8.12452Z" fill="#CB2128" />
				</g>
				<mask id="mask10_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask10_26884_6777)">
					<path d="M-0.25293 6.73517C9.86923 4.45363 20.3662 4.45363 30.4883 6.73517C30.4883 6.27127 30.4883 5.80738 30.4883 5.34348C20.3662 3.06194 9.86923 3.06194 -0.25293 5.34348C-0.25293 5.80738 -0.25293 6.27127 -0.25293 6.73517Z" fill="white" />
				</g>
				<mask id="mask11_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask11_26884_6777)">
					<path
						d="M-0.25293 5.34345C9.86923 3.06191 20.3662 3.06191 30.4883 5.34345C30.4883 4.87955 30.4883 4.41565 30.4883 3.95176C20.3662 1.67022 9.86923 1.67022 -0.25293 3.95176C-0.25293 4.41565 -0.25293 4.87955 -0.25293 5.34345Z"
						fill="#CB2128"
					/>
				</g>
				<mask id="mask12_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask12_26884_6777)">
					<path d="M-0.25293 3.9517C9.86923 1.67016 20.3662 1.67016 30.4883 3.9517C30.4883 3.48815 30.4883 3.0246 30.4883 2.56113C20.3662 0.27959 9.86923 0.27959 -0.25293 2.56113C-0.25293 3.0246 -0.25293 3.48815 -0.25293 3.9517Z" fill="white" />
				</g>
				<mask id="mask13_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask13_26884_6777)">
					<path
						d="M-0.25293 2.56129C9.86923 0.279747 20.3662 0.279747 30.4883 2.56129C30.4883 2.09722 30.4883 1.63323 30.4883 1.16916C20.3662 -1.11238 9.86923 -1.11238 -0.25293 1.16916C-0.25293 1.63315 -0.25293 2.09722 -0.25293 2.56129Z"
						fill="#CB2128"
					/>
				</g>
				<mask id="mask14_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask14_26884_6777)">
					<path
						d="M-0.25293 10.9084C4.3699 9.86638 9.07072 9.30026 13.7784 9.20999C13.7784 5.96349 13.7784 2.71708 13.7784 -0.529419C9.07072 -0.439152 4.36981 0.126968 -0.25293 1.16894C-0.25293 4.41544 -0.25293 7.66194 -0.25293 10.9084Z"
						fill="#2C3661"
					/>
				</g>
				<mask id="mask15_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask15_26884_6777)">
					<path
						d="M1.00051 1.97241C1.0402 2.08728 1.07989 2.20215 1.11957 2.31711C1.24629 2.29121 1.37309 2.26565 1.49997 2.24044C1.39765 2.33634 1.29525 2.4325 1.19294 2.52883C1.23194 2.64361 1.27086 2.7584 1.30986 2.87318C1.20677 2.818 1.1036 2.76307 1.00051 2.70841C0.897676 2.80561 0.794846 2.90298 0.692101 3.00061C0.732306 2.86945 0.772424 2.73838 0.812543 2.60723C0.709798 2.55317 0.607053 2.49929 0.504395 2.44566C0.630248 2.41872 0.756188 2.39221 0.882041 2.36605C0.921645 2.23481 0.961076 2.10357 1.00051 1.97241Z"
						fill="white"
					/>
				</g>
				<mask id="mask16_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask16_26884_6777)">
					<path
						d="M1.00051 3.599C1.0402 3.71309 1.07989 3.82726 1.11957 3.94144C1.24629 3.91554 1.37309 3.88998 1.49997 3.86478C1.39765 3.96033 1.29525 4.05605 1.19294 4.15204C1.23194 4.26751 1.27086 4.38308 1.30986 4.49864C1.20677 4.44346 1.1036 4.38853 1.00051 4.33387C0.897676 4.43107 0.794846 4.52844 0.692101 4.62607C0.732306 4.49422 0.772424 4.36228 0.812543 4.23052C0.709798 4.17681 0.607053 4.12336 0.504395 4.07009C0.630248 4.04314 0.756188 4.01664 0.882041 3.99047C0.921645 3.85984 0.961076 3.72938 1.00051 3.599Z"
						fill="white"
					/>
				</g>
				<mask id="mask17_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask17_26884_6777)">
					<path
						d="M1.00051 5.22339C1.0402 5.33774 1.07989 5.45217 1.11957 5.56661C1.24629 5.54071 1.37309 5.51515 1.49997 5.48995C1.39765 5.58584 1.29525 5.682 1.19294 5.77833C1.23194 5.89346 1.27086 6.00859 1.30986 6.12381C1.20677 6.06871 1.1036 6.0137 1.00051 5.95904C0.897676 6.05624 0.794846 6.15361 0.692101 6.25124C0.732306 6.11974 0.772424 5.98823 0.812543 5.85682C0.709798 5.80276 0.607053 5.74888 0.504395 5.69525C0.630248 5.66831 0.756188 5.64181 0.882041 5.61564C0.921645 5.48483 0.961076 5.35411 1.00051 5.22339Z"
						fill="white"
					/>
				</g>
				<mask id="mask18_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask18_26884_6777)">
					<path
						d="M1.00051 6.84863C1.0402 6.96298 1.07989 7.07742 1.11957 7.19177C1.24629 7.16587 1.37309 7.14031 1.49997 7.1151C1.39765 7.21135 1.29525 7.30794 1.19294 7.40462C1.23194 7.51897 1.27086 7.6334 1.30986 7.74784C1.20677 7.69344 1.1036 7.63929 1.00051 7.58541C0.897676 7.68183 0.794846 7.77842 0.692101 7.87527C0.732306 7.74446 0.772424 7.61374 0.812543 7.4831C0.709798 7.42861 0.607053 7.37438 0.504395 7.32041C0.630248 7.29347 0.756188 7.26696 0.882041 7.2408C0.921645 7.10999 0.961076 6.97936 1.00051 6.84863Z"
						fill="white"
					/>
				</g>
				<mask id="mask19_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask19_26884_6777)">
					<path
						d="M1.00051 8.47388C1.0402 8.58866 1.07989 8.70344 1.11957 8.81823C1.24629 8.79232 1.37309 8.76677 1.49997 8.74156C1.39765 8.83711 1.29525 8.93284 1.19294 9.02882C1.23194 9.14395 1.27086 9.25908 1.30986 9.3743C1.20677 9.31911 1.1036 9.26419 1.00051 9.20953C0.897676 9.30672 0.794846 9.4041 0.692101 9.50173C0.732306 9.37022 0.772424 9.23872 0.812543 9.10731C0.709798 9.0536 0.607053 9.00015 0.504395 8.94687C0.630248 8.91993 0.756188 8.89342 0.882041 8.86726C0.921645 8.73602 0.961076 8.60495 1.00051 8.47388Z"
						fill="white"
					/>
				</g>
				<mask id="mask20_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask20_26884_6777)">
					<path
						d="M2.11413 2.60791C2.15218 2.72365 2.19033 2.83938 2.2283 2.9552C2.35639 2.93121 2.48456 2.90747 2.61282 2.88417C2.51024 2.97911 2.40759 3.07432 2.30501 3.16978C2.34384 3.28483 2.38259 3.39996 2.42133 3.515C2.31893 3.45834 2.21653 3.40195 2.11413 3.34581C2.01164 3.44076 1.90915 3.53596 1.80667 3.63143C1.84567 3.50157 1.88476 3.37172 1.92376 3.24195C1.82118 3.18538 1.71861 3.12898 1.61621 3.07285C1.74344 3.04781 1.87067 3.02312 1.99798 2.99878C2.03673 2.86849 2.07538 2.73811 2.11413 2.60791Z"
						fill="white"
					/>
				</g>
				<mask id="mask21_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask21_26884_6777)">
					<path
						d="M2.11413 4.23425C2.15218 4.34964 2.19033 4.46495 2.2283 4.58042C2.35639 4.55643 2.48456 4.53269 2.61282 4.50939C2.51024 4.60399 2.40759 4.69884 2.30501 4.79388C2.34384 4.90892 2.38259 5.02405 2.42133 5.13909C2.31893 5.08287 2.21653 5.02682 2.11413 4.97103C2.01164 5.06563 1.90915 5.16049 1.80667 5.25552C1.84567 5.12566 1.88476 4.99581 1.92376 4.86604C1.82118 4.80981 1.71861 4.75385 1.61621 4.69806C1.74344 4.67303 1.87067 4.64834 1.99798 4.624C2.03673 4.49405 2.07538 4.36411 2.11413 4.23425Z"
						fill="white"
					/>
				</g>
				<mask id="mask22_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask22_26884_6777)">
					<path
						d="M2.11413 5.85962C2.15218 5.97501 2.19033 6.0904 2.2283 6.20579C2.35639 6.18179 2.48456 6.15805 2.61282 6.13475C2.51024 6.229 2.40759 6.32343 2.30501 6.41812C2.34384 6.53394 2.38259 6.64976 2.42133 6.76567C2.31893 6.70867 2.21653 6.65193 2.11413 6.59527C2.01164 6.69065 1.90915 6.7862 1.80667 6.8821C1.84567 6.75146 1.88476 6.62083 1.92376 6.49036C1.82118 6.43449 1.71861 6.37887 1.61621 6.32352C1.74344 6.29848 1.87067 6.27379 1.99798 6.24945C2.03673 6.11942 2.07538 5.98948 2.11413 5.85962Z"
						fill="white"
					/>
				</g>
				<mask id="mask23_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask23_26884_6777)">
					<path
						d="M2.11413 7.48584C2.15218 7.6008 2.19033 7.71584 2.2283 7.8308C2.35639 7.8068 2.48456 7.78306 2.61282 7.75976C2.51024 7.85436 2.40759 7.94922 2.30501 8.04425C2.34384 8.15929 2.38259 8.27442 2.42133 8.38955C2.31893 8.33255 2.21653 8.27581 2.11413 8.21915C2.01164 8.31453 1.90915 8.41008 1.80667 8.50598C1.84567 8.37612 1.88476 8.24627 1.92376 8.1165C1.82118 8.06028 1.71861 8.00423 1.61621 7.94852C1.74344 7.92349 1.87067 7.8988 1.99798 7.87446C2.03673 7.74495 2.07538 7.61535 2.11413 7.48584Z"
						fill="white"
					/>
				</g>
				<mask id="mask24_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask24_26884_6777)">
					<path
						d="M3.24183 1.54834C3.28221 1.66442 3.3225 1.78059 3.36287 1.89667C3.49027 1.87493 3.61776 1.85353 3.74516 1.83248C3.64181 1.92509 3.53847 2.01795 3.43521 2.11099C3.47481 2.2269 3.51433 2.34299 3.55402 2.45898C3.4499 2.40051 3.34586 2.34229 3.24183 2.28425C3.13977 2.37781 3.0378 2.47154 2.93583 2.56553C2.97492 2.43594 3.01409 2.30634 3.05318 2.17683C2.95052 2.11922 2.84769 2.06196 2.74512 2.00487C2.87157 1.98218 2.99794 1.95974 3.1244 1.93774C3.16348 1.80797 3.20266 1.67811 3.24183 1.54834Z"
						fill="white"
					/>
				</g>
				<mask id="mask25_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask25_26884_6777)">
					<path
						d="M3.24183 3.17468C3.28221 3.28999 3.3225 3.40537 3.36287 3.52076C3.49027 3.49902 3.61776 3.47762 3.74516 3.45657C3.64181 3.54883 3.53847 3.64127 3.43521 3.73404C3.47481 3.85073 3.51433 3.96751 3.55402 4.08428C3.4499 4.02581 3.34586 3.9676 3.24183 3.90955C3.13977 4.00311 3.0378 4.09685 2.93583 4.19084C2.97492 4.06046 3.01409 3.93017 3.05318 3.79988C2.95052 3.74271 2.84769 3.68571 2.74512 3.62896C2.87157 3.60627 2.99794 3.58383 3.1244 3.56183C3.16348 3.43284 3.20266 3.30367 3.24183 3.17468Z"
						fill="white"
					/>
				</g>
				<mask id="mask26_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask26_26884_6777)">
					<path
						d="M3.24183 4.79932C3.28221 4.91488 3.3225 5.03053 3.36287 5.14618C3.49027 5.12443 3.61776 5.10304 3.74516 5.08198C3.64181 5.17459 3.53847 5.26746 3.43521 5.3605C3.47481 5.47684 3.51433 5.59318 3.55402 5.70961C3.4499 5.65114 3.34586 5.59292 3.24183 5.53488C3.13977 5.62844 3.0378 5.72217 2.93583 5.81616C2.97492 5.68622 3.01409 5.55628 3.05318 5.42633C2.95052 5.36873 2.84769 5.31146 2.74512 5.25438C2.87157 5.23168 2.99794 5.20924 3.1244 5.18724C3.16348 5.0579 3.20266 4.92857 3.24183 4.79932Z"
						fill="white"
					/>
				</g>
				<mask id="mask27_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask27_26884_6777)">
					<path
						d="M3.24183 6.42444C3.28221 6.54 3.3225 6.65565 3.36287 6.7713C3.49027 6.74956 3.61776 6.72816 3.74516 6.70711C3.64181 6.80015 3.53847 6.89327 3.43521 6.98683C3.47481 7.1024 3.51433 7.21804 3.55402 7.33369C3.4499 7.276 3.34586 7.21848 3.24183 7.1613C3.13977 7.25408 3.0378 7.34712 2.93583 7.44033C2.97492 7.31108 3.01409 7.18192 3.05318 7.05276C2.95052 6.9948 2.84769 6.93711 2.74512 6.87959C2.87157 6.85689 2.99794 6.83445 3.1244 6.81245C3.16348 6.68303 3.20266 6.55369 3.24183 6.42444Z"
						fill="white"
					/>
				</g>
				<mask id="mask28_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask28_26884_6777)">
					<path
						d="M3.24183 8.04968C3.28221 8.16568 3.3225 8.28167 3.36287 8.39776C3.49027 8.37601 3.61776 8.35462 3.74516 8.33357C3.64181 8.42583 3.53847 8.51826 3.43521 8.61095C3.47481 8.72729 3.51433 8.84364 3.55402 8.96006C3.4499 8.90159 3.34586 8.84338 3.24183 8.78533C3.13977 8.87889 3.0378 8.97263 2.93583 9.06662C2.97492 8.93667 3.01409 8.80673 3.05318 8.67679C2.95052 8.61961 2.84769 8.5627 2.74512 8.50596C2.87157 8.48326 2.99794 8.46082 3.1244 8.43882C3.16348 8.30905 3.20266 8.17937 3.24183 8.04968Z"
						fill="white"
					/>
				</g>
				<mask id="mask29_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask29_26884_6777)">
					<path
						d="M4.36245 2.22021C4.40171 2.33708 4.44088 2.45385 4.48006 2.5708C4.60849 2.55105 4.73683 2.53165 4.86526 2.51259C4.76175 2.60433 4.65823 2.69624 4.55471 2.78833C4.59431 2.90458 4.63409 3.02084 4.67378 3.13709C4.57 3.07723 4.46614 3.01763 4.36245 2.95821C4.25979 3.04977 4.15705 3.14151 4.0543 3.2336C4.09399 3.10495 4.13368 2.97631 4.17346 2.84775C4.06925 2.78798 3.96504 2.72847 3.86084 2.66913C3.98901 2.64816 4.11736 2.62763 4.24562 2.60745C4.28462 2.4782 4.32362 2.34912 4.36245 2.22021Z"
						fill="white"
					/>
				</g>
				<mask id="mask30_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask30_26884_6777)">
					<path
						d="M4.36245 3.84656C4.40171 3.96299 4.44088 4.07942 4.48006 4.19602C4.60849 4.17627 4.73683 4.15686 4.86526 4.1378C4.76175 4.22911 4.65823 4.32059 4.55471 4.41233C4.59431 4.52859 4.63409 4.64484 4.67378 4.7611C4.57 4.70158 4.46614 4.64233 4.36245 4.58334C4.25979 4.67456 4.15705 4.76586 4.0543 4.8576C4.09399 4.72896 4.13368 4.60031 4.17346 4.47176C4.06925 4.41233 3.96504 4.35316 3.86084 4.29426C3.98901 4.27329 4.11736 4.25276 4.24562 4.23258C4.28462 4.10385 4.32362 3.9752 4.36245 3.84656Z"
						fill="white"
					/>
				</g>
				<mask id="mask31_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask31_26884_6777)">
					<path
						d="M4.36245 5.47192C4.40171 5.58835 4.44088 5.70478 4.48006 5.8213C4.60849 5.80155 4.73683 5.78214 4.86526 5.76308C4.76175 5.85396 4.65823 5.94518 4.55471 6.03648C4.59431 6.15352 4.63409 6.27055 4.67378 6.38759C4.57 6.32738 4.46614 6.26735 4.36245 6.20758C4.25979 6.29958 4.15705 6.39166 4.0543 6.48409C4.09399 6.35467 4.13368 6.22525 4.17346 6.09591C4.06925 6.03683 3.96504 5.9781 3.86084 5.91954C3.98901 5.89857 4.11736 5.87804 4.24562 5.85786C4.28462 5.72921 4.32362 5.60057 4.36245 5.47192Z"
						fill="white"
					/>
				</g>
				<mask id="mask32_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask32_26884_6777)">
					<path
						d="M4.36245 7.09827C4.40171 7.21435 4.44088 7.33043 4.48006 7.44651C4.60849 7.42676 4.73683 7.40736 4.86526 7.3883C4.76175 7.47961 4.65823 7.57117 4.55471 7.66291C4.59431 7.77917 4.63409 7.89542 4.67378 8.01168C4.57 7.95147 4.46614 7.89144 4.36245 7.83167C4.25979 7.92367 4.15705 8.01575 4.0543 8.10819C4.09399 7.97954 4.13368 7.8509 4.17346 7.72225C4.06925 7.66283 3.96504 7.60366 3.86084 7.54475C3.98901 7.52379 4.11736 7.50326 4.24562 7.48307C4.28462 7.35469 4.32362 7.22648 4.36245 7.09827Z"
						fill="white"
					/>
				</g>
				<mask id="mask33_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask33_26884_6777)">
					<path
						d="M5.49654 1.19739C5.53734 1.31477 5.57806 1.43215 5.61887 1.54962C5.74524 1.53229 5.87161 1.51532 5.99806 1.49868C5.89394 1.58791 5.78991 1.6774 5.68596 1.76715C5.72746 1.88418 5.76912 2.00122 5.8107 2.11834C5.70589 2.05649 5.60126 1.99481 5.49654 1.93347C5.39362 2.02374 5.2907 2.11418 5.18779 2.20488C5.22679 2.07658 5.26588 1.94837 5.30488 1.82016C5.20282 1.75909 5.10059 1.69828 4.99854 1.63764C5.12559 1.61901 5.25256 1.60082 5.3797 1.58289C5.41871 1.45433 5.45762 1.32577 5.49654 1.19739Z"
						fill="white"
					/>
				</g>
				<mask id="mask34_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask34_26884_6777)">
					<path
						d="M5.49654 2.82373C5.53734 2.94033 5.57806 3.05702 5.61887 3.17371C5.74524 3.15638 5.87161 3.13941 5.99806 3.12277C5.89394 3.21165 5.78991 3.30079 5.68596 3.39011C5.72746 3.50784 5.76912 3.62565 5.8107 3.74355C5.70589 3.6817 5.60126 3.62002 5.49654 3.55869C5.39362 3.64896 5.2907 3.7394 5.18779 3.8301C5.22679 3.70111 5.26588 3.57203 5.30488 3.44313C5.20282 3.3824 5.10059 3.32193 4.99854 3.26173C5.12559 3.2431 5.25256 3.22491 5.3797 3.20698C5.41871 3.0792 5.45762 2.95142 5.49654 2.82373Z"
						fill="white"
					/>
				</g>
				<mask id="mask35_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask35_26884_6777)">
					<path
						d="M5.49654 4.44824C5.53734 4.5651 5.57806 4.68205 5.61887 4.799C5.74524 4.78168 5.87161 4.7647 5.99806 4.74806C5.89394 4.83729 5.78991 4.92678 5.68596 5.01653C5.72746 5.13391 5.76912 5.25129 5.8107 5.36876C5.70589 5.30691 5.60126 5.24523 5.49654 5.18389C5.39362 5.27416 5.2907 5.3646 5.18779 5.4553C5.22679 5.32666 5.26588 5.19801 5.30488 5.06946C5.20282 5.00838 5.10059 4.94757 4.99854 4.88693C5.12559 4.8683 5.25256 4.85011 5.3797 4.83218C5.41871 4.70423 5.45762 4.57619 5.49654 4.44824Z"
						fill="white"
					/>
				</g>
				<mask id="mask36_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask36_26884_6777)">
					<path
						d="M5.49654 6.07361C5.53734 6.19047 5.57806 6.30742 5.61887 6.42437C5.74524 6.40704 5.87161 6.39006 5.99806 6.37343C5.89394 6.46309 5.78991 6.55293 5.68596 6.64302C5.72746 6.75962 5.76912 6.87631 5.8107 6.99309C5.70589 6.93193 5.60126 6.87111 5.49654 6.81047C5.39362 6.89996 5.2907 6.98971 5.18779 7.07963C5.22679 6.95168 5.26588 6.82381 5.30488 6.69604C5.20282 6.63462 5.10059 6.57337 4.99854 6.51238C5.12559 6.49376 5.25256 6.47557 5.3797 6.45763C5.41871 6.32951 5.45762 6.20156 5.49654 6.07361Z"
						fill="white"
					/>
				</g>
				<mask id="mask37_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask37_26884_6777)">
					<path
						d="M5.49654 7.69873C5.53734 7.81603 5.57806 7.93332 5.61887 8.05062C5.74524 8.03329 5.87161 8.01631 5.99806 7.99968C5.89394 8.08856 5.78991 8.17762 5.68596 8.26702C5.72746 8.3844 5.76912 8.50178 5.8107 8.61925C5.70589 8.5574 5.60126 8.49572 5.49654 8.43438C5.39362 8.52465 5.2907 8.61509 5.18779 8.70579C5.22679 8.57715 5.26588 8.4485 5.30488 8.31995C5.20282 8.25922 5.10059 8.19884 4.99854 8.13855C5.12559 8.11992 5.25256 8.10173 5.3797 8.0838C5.41871 7.9555 5.45762 7.82711 5.49654 7.69873Z"
						fill="white"
					/>
				</g>
				<mask id="mask38_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask38_26884_6777)">
					<path
						d="M6.6212 1.90588C6.66029 2.02405 6.69938 2.14221 6.73847 2.26046C6.86673 2.24495 6.99499 2.22979 7.12333 2.21506C7.01998 2.30325 6.91664 2.39178 6.81329 2.48041C6.85178 2.59813 6.89026 2.71578 6.92884 2.83359C6.82635 2.77009 6.72378 2.70694 6.6212 2.64387C6.51786 2.73215 6.4146 2.8206 6.31134 2.90922C6.34991 2.78205 6.38848 2.65488 6.42714 2.52771C6.32379 2.46438 6.22053 2.40123 6.11719 2.33825C6.24536 2.32162 6.37362 2.30533 6.50188 2.28939C6.54148 2.16153 6.58134 2.03375 6.6212 1.90588Z"
						fill="white"
					/>
				</g>
				<mask id="mask39_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask39_26884_6777)">
					<path
						d="M6.6212 3.53235C6.66029 3.65016 6.69938 3.76789 6.73847 3.88579C6.86673 3.87029 6.99499 3.85513 7.12333 3.8404C7.01998 3.92824 6.91664 4.01634 6.81329 4.10462C6.85178 4.22235 6.89026 4.33999 6.92884 4.4578C6.82635 4.39465 6.72378 4.33185 6.6212 4.26921C6.51786 4.35705 6.4146 4.44516 6.31134 4.53343C6.34991 4.40626 6.38848 4.27909 6.42714 4.15192C6.32379 4.08894 6.22053 4.02613 6.11719 3.96367C6.24536 3.94704 6.37362 3.93075 6.50188 3.91481C6.54148 3.78721 6.58134 3.65978 6.6212 3.53235Z"
						fill="white"
					/>
				</g>
				<mask id="mask40_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask40_26884_6777)">
					<path
						d="M6.6212 5.15759C6.66029 5.27532 6.69938 5.39314 6.73847 5.51095C6.86673 5.49544 6.99499 5.48028 7.12333 5.46556C7.01998 5.55305 6.91664 5.64072 6.81329 5.72865C6.85178 5.84716 6.89026 5.96558 6.92884 6.08417C6.82635 6.02033 6.72378 5.95674 6.6212 5.89333C6.51786 5.98195 6.4146 6.07075 6.31134 6.15989C6.34991 6.03185 6.38848 5.90399 6.42714 5.77603C6.32379 5.7134 6.22053 5.65103 6.11719 5.58892C6.24536 5.57228 6.37362 5.556 6.50188 5.54006C6.54148 5.41245 6.58134 5.28502 6.6212 5.15759Z"
						fill="white"
					/>
				</g>
				<mask id="mask41_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask41_26884_6777)">
					<path
						d="M6.6212 6.78394C6.66029 6.90132 6.69938 7.0187 6.73847 7.13617C6.86673 7.12066 6.99499 7.1055 7.12333 7.09077C7.01998 7.17862 6.91664 7.26672 6.81329 7.35499C6.85178 7.47272 6.89026 7.59045 6.92884 7.70818C6.82635 7.64433 6.72378 7.58075 6.6212 7.51733C6.51786 7.60596 6.4146 7.69475 6.31134 7.78389C6.34991 7.65663 6.38848 7.52946 6.42714 7.40238C6.32379 7.3394 6.22053 7.27659 6.11719 7.21413C6.24536 7.1975 6.37362 7.18121 6.50188 7.16527C6.54148 7.0381 6.58134 6.91102 6.6212 6.78394Z"
						fill="white"
					/>
				</g>
				<mask id="mask42_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask42_26884_6777)">
					<path
						d="M7.75678 0.920532C7.79673 1.0393 7.83659 1.15816 7.87645 1.2771C8.00393 1.26384 8.13142 1.25093 8.2589 1.23829C8.15564 1.32405 8.05238 1.40999 7.94912 1.49627C7.98984 1.61478 8.03039 1.73328 8.07111 1.85188C7.96631 1.78656 7.86158 1.7215 7.75686 1.65662C7.65421 1.74342 7.55146 1.83048 7.44872 1.91772C7.48737 1.7908 7.52595 1.66389 7.5646 1.53698C7.46117 1.47262 7.35774 1.4086 7.25439 1.34467C7.38248 1.3302 7.51057 1.31599 7.63891 1.30222C7.67809 1.17496 7.71743 1.0477 7.75678 0.920532Z"
						fill="white"
					/>
				</g>
				<mask id="mask43_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask43_26884_6777)">
					<path
						d="M7.75678 2.547C7.79673 2.66507 7.83659 2.78315 7.87645 2.90122C8.00393 2.88797 8.13142 2.87506 8.2589 2.86241C8.15564 2.94783 8.05238 3.03342 7.94912 3.11927C7.98984 3.23847 8.03039 3.35776 8.07111 3.47713C7.96631 3.41181 7.86158 3.34675 7.75686 3.28187C7.65421 3.36867 7.55146 3.45573 7.44872 3.54306C7.48737 3.41536 7.52595 3.28767 7.5646 3.16007C7.46117 3.09614 7.35774 3.03238 7.25439 2.96888C7.38248 2.95441 7.51057 2.9402 7.63891 2.92643C7.67809 2.79987 7.71743 2.67339 7.75678 2.547Z"
						fill="white"
					/>
				</g>
				<mask id="mask44_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask44_26884_6777)">
					<path
						d="M7.75678 4.17151C7.79673 4.28984 7.83659 4.40818 7.87645 4.5266C8.00393 4.51335 8.13142 4.50044 8.2589 4.48779C8.15564 4.57355 8.05238 4.65949 7.94912 4.74577C7.98984 4.86463 8.03039 4.98357 8.07111 5.10251C7.96631 5.03719 7.86158 4.97213 7.75686 4.90725C7.65421 4.99405 7.55146 5.08111 7.44872 5.16834C7.48737 5.04109 7.52595 4.91383 7.5646 4.78657C7.46117 4.72221 7.35774 4.65819 7.25439 4.59426C7.38248 4.57979 7.51057 4.56558 7.63891 4.55181C7.67809 4.42498 7.71743 4.29816 7.75678 4.17151Z"
						fill="white"
					/>
				</g>
				<mask id="mask45_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask45_26884_6777)">
					<path
						d="M7.75678 5.79675C7.79673 5.915 7.83659 6.03334 7.87645 6.15176C8.00393 6.1385 8.13142 6.1256 8.2589 6.11295C8.15564 6.19914 8.05238 6.28542 7.94912 6.37205C7.98984 6.49022 8.03039 6.60838 8.07111 6.72654C7.96631 6.66191 7.86158 6.59763 7.75686 6.53353C7.65421 6.61955 7.55146 6.70583 7.44872 6.79238C7.48737 6.66581 7.52595 6.53933 7.5646 6.41277C7.46117 6.34806 7.35774 6.28361 7.25439 6.21941C7.38248 6.20495 7.51057 6.19074 7.63891 6.17697C7.67809 6.05023 7.71743 5.92349 7.75678 5.79675Z"
						fill="white"
					/>
				</g>
				<mask id="mask46_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask46_26884_6777)">
					<path
						d="M7.75678 7.42212C7.79673 7.5408 7.83659 7.65957 7.87645 7.77834C8.00393 7.76508 8.13142 7.75217 8.2589 7.73953C8.15564 7.82494 8.05238 7.91053 7.94912 7.9963C7.98984 8.11515 8.03039 8.23409 8.07111 8.35303C7.96631 8.28771 7.86158 8.22266 7.75686 8.15777C7.65421 8.24457 7.55146 8.33163 7.44872 8.41896C7.48737 8.29161 7.52595 8.16436 7.5646 8.0371C7.46117 7.97317 7.35774 7.90949 7.25439 7.84599C7.38248 7.83153 7.51057 7.81732 7.63891 7.80355C7.67809 7.67637 7.71743 7.5492 7.75678 7.42212Z"
						fill="white"
					/>
				</g>
				<mask id="mask47_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask47_26884_6777)">
					<path
						d="M8.8825 1.66626C8.92245 1.78563 8.9624 1.90501 9.00234 2.02447C9.13146 2.01312 9.26058 2.00212 9.38978 1.99155C9.28566 2.07645 9.18154 2.16152 9.07742 2.24693C9.1166 2.36587 9.15577 2.48473 9.19495 2.60367C9.09083 2.53697 8.98662 2.47052 8.8825 2.40425C8.77898 2.48906 8.67538 2.57413 8.57186 2.65946C8.61181 2.53341 8.65176 2.40737 8.6917 2.28141C8.58844 2.21462 8.4851 2.14809 8.38184 2.08173C8.51001 2.06926 8.63835 2.05722 8.76661 2.04552C8.8051 1.91913 8.84384 1.79265 8.8825 1.66626Z"
						fill="white"
					/>
				</g>
				<mask id="mask48_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask48_26884_6777)">
					<path
						d="M8.8825 3.2926C8.92245 3.41163 8.9624 3.53057 9.00234 3.64969C9.13146 3.63834 9.26058 3.62734 9.38978 3.61677C9.28566 3.70132 9.18154 3.78604 9.07742 3.87102C9.1166 3.98996 9.15577 4.10882 9.19495 4.22776C9.09083 4.1614 8.98662 4.09539 8.8825 4.02947C8.77898 4.11393 8.67538 4.19865 8.57186 4.28355C8.61181 4.1575 8.65176 4.03146 8.6917 3.9055C8.58844 3.83906 8.4851 3.77296 8.38184 3.70695C8.51001 3.69447 8.63835 3.68243 8.76661 3.67074C8.8051 3.54461 8.84384 3.41865 8.8825 3.2926Z"
						fill="white"
					/>
				</g>
				<mask id="mask49_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask49_26884_6777)">
					<path
						d="M8.8825 4.91797C8.92245 5.037 8.9624 5.15594 9.00234 5.27497C9.13146 5.26362 9.26058 5.25262 9.38978 5.24205C9.28566 5.32616 9.18154 5.41054 9.07742 5.49509C9.1166 5.61472 9.15577 5.73444 9.19495 5.85417C9.09083 5.78711 8.98662 5.72024 8.8825 5.65362C8.77898 5.73886 8.67538 5.82428 8.57186 5.90996C8.61181 5.78313 8.65176 5.65631 8.6917 5.52957C8.58844 5.46356 8.4851 5.39781 8.38184 5.33223C8.51001 5.31975 8.63835 5.30771 8.76661 5.29602C8.8051 5.16997 8.84384 5.04393 8.8825 4.91797Z"
						fill="white"
					/>
				</g>
				<mask id="mask50_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask50_26884_6777)">
					<path
						d="M8.8825 6.54419C8.92245 6.66278 8.9624 6.78138 9.00234 6.90006C9.13146 6.88871 9.26058 6.87771 9.38978 6.86714C9.28566 6.95169 9.18154 7.03641 9.07742 7.1214C9.1166 7.24034 9.15577 7.35919 9.19495 7.47822C9.09083 7.41117 8.98662 7.34429 8.8825 7.27767C8.77898 7.36292 8.67538 7.44833 8.57186 7.53401C8.61181 7.40796 8.65176 7.28192 8.6917 7.15596C8.58844 7.08952 8.4851 7.02342 8.38184 6.95741C8.51001 6.94493 8.63835 6.93289 8.76661 6.9212C8.8051 6.7955 8.84384 6.6698 8.8825 6.54419Z"
						fill="white"
					/>
				</g>
				<mask id="mask51_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask51_26884_6777)">
					<path
						d="M10.0249 0.717651C10.0651 0.837805 10.1053 0.957873 10.1455 1.07811C10.2732 1.06902 10.401 1.06027 10.5287 1.05186C10.4249 1.13425 10.3212 1.21689 10.2174 1.29971C10.2569 1.4196 10.2964 1.53958 10.3359 1.65948C10.2322 1.5907 10.1285 1.52209 10.0249 1.45374C9.92221 1.53716 9.81947 1.62084 9.71672 1.70479C9.75521 1.57909 9.7937 1.45348 9.83218 1.32786C9.72875 1.26012 9.62532 1.19264 9.52197 1.12541C9.6504 1.1151 9.77883 1.10514 9.90727 1.09553C9.94644 0.969568 9.9857 0.84361 10.0249 0.717651Z"
						fill="white"
					/>
				</g>
				<mask id="mask52_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask52_26884_6777)">
					<path
						d="M10.0249 2.34412C10.0651 2.46349 10.1053 2.58287 10.1455 2.70224C10.2732 2.69314 10.401 2.68439 10.5287 2.67599C10.4249 2.75803 10.3212 2.84024 10.2174 2.9228C10.2569 3.04347 10.2964 3.16414 10.3359 3.28482C10.2322 3.21603 10.1285 3.14742 10.0249 3.07907C9.92221 3.1625 9.81947 3.24618 9.71672 3.33012C9.75521 3.20373 9.7937 3.07734 9.83218 2.95104C9.72875 2.88364 9.62532 2.8165 9.52197 2.74963C9.6504 2.73932 9.77883 2.72935 9.90727 2.71974C9.94644 2.59447 9.9857 2.46929 10.0249 2.34412Z"
						fill="white"
					/>
				</g>
				<mask id="mask53_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask53_26884_6777)">
					<path
						d="M10.0249 3.96863C10.0651 4.08826 10.1053 4.2079 10.1455 4.32762C10.2732 4.31852 10.401 4.30977 10.5287 4.30137C10.4249 4.38375 10.3212 4.4664 10.2174 4.54921C10.2569 4.66945 10.2964 4.78978 10.3359 4.91011C10.2322 4.84133 10.1285 4.77272 10.0249 4.70437C9.92221 4.78779 9.81947 4.87147 9.71672 4.95542C9.75521 4.82937 9.7937 4.70333 9.83218 4.57745C9.72875 4.50971 9.62532 4.44223 9.52197 4.375C9.6504 4.36469 9.77883 4.35473 9.90727 4.34512C9.94644 4.2195 9.9857 4.09407 10.0249 3.96863Z"
						fill="white"
					/>
				</g>
				<mask id="mask54_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask54_26884_6777)">
					<path
						d="M10.0249 5.59387C10.0651 5.71351 10.1053 5.83314 10.1455 5.95278C10.2732 5.94368 10.401 5.93493 10.5287 5.92653C10.4249 6.00934 10.3212 6.09233 10.2174 6.17558C10.2569 6.29504 10.2964 6.41468 10.3359 6.53423C10.2322 6.46614 10.1285 6.39839 10.0249 6.33074C9.92221 6.41347 9.81947 6.49637 9.71672 6.57953C9.75521 6.45427 9.7937 6.329 9.83218 6.20374C9.72875 6.13565 9.62532 6.06782 9.52197 6.00016C9.6504 5.98985 9.77883 5.97989 9.90727 5.97027C9.94644 5.84466 9.9857 5.71922 10.0249 5.59387Z"
						fill="white"
					/>
				</g>
				<mask id="mask55_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask55_26884_6777)">
					<path
						d="M10.0249 7.21912C10.0651 7.3391 10.1053 7.45916 10.1455 7.57923C10.2732 7.57014 10.401 7.56139 10.5287 7.55298C10.4249 7.63502 10.3212 7.71723 10.2174 7.7997C10.2569 7.91994 10.2964 8.04027 10.3359 8.1606C10.2322 8.09181 10.1285 8.0232 10.0249 7.95485C9.92221 8.03828 9.81947 8.12196 9.71672 8.2059C9.75521 8.07986 9.7937 7.95381 9.83218 7.82786C9.72875 7.76055 9.62532 7.69341 9.52197 7.62653C9.6504 7.61622 9.77883 7.60626 9.90727 7.59664C9.94644 7.47077 9.9857 7.3449 10.0249 7.21912Z"
						fill="white"
					/>
				</g>
				<mask id="mask56_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask56_26884_6777)">
					<path
						d="M11.1528 1.5C11.1923 1.62076 11.2317 1.74143 11.2711 1.86219C11.3997 1.85509 11.5284 1.84833 11.657 1.84201C11.5539 1.92344 11.4509 2.00513 11.3478 2.087C11.3865 2.20715 11.4252 2.32739 11.4641 2.44763C11.3603 2.37755 11.2565 2.30764 11.1528 2.23799C11.0485 2.31951 10.9443 2.4012 10.84 2.48315C10.88 2.3584 10.9199 2.23375 10.9599 2.10909C10.8569 2.03892 10.754 1.96892 10.6509 1.89919C10.7781 1.89104 10.9055 1.88325 11.0326 1.8758C11.0727 1.75044 11.1128 1.62518 11.1528 1.5Z"
						fill="white"
					/>
				</g>
				<mask id="mask57_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask57_26884_6777)">
					<path
						d="M11.1528 3.12646C11.1923 3.24679 11.2317 3.36712 11.2711 3.48753C11.3997 3.48043 11.5284 3.47367 11.657 3.46735C11.5539 3.54843 11.4509 3.62969 11.3478 3.71121C11.3865 3.83136 11.4252 3.9516 11.4641 4.07184C11.3603 4.00211 11.2565 3.93263 11.1528 3.86333C11.0485 3.9445 10.9443 4.02576 10.84 4.10736C10.88 3.98262 10.9199 3.85796 10.9599 3.7333C10.8569 3.66348 10.754 3.59391 10.6509 3.52452C10.7781 3.51638 10.9055 3.50858 11.0326 3.50113C11.0727 3.37613 11.1128 3.2513 11.1528 3.12646Z"
						fill="white"
					/>
				</g>
				<mask id="mask58_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask58_26884_6777)">
					<path
						d="M11.1528 4.75171C11.1923 4.87204 11.2317 4.99236 11.2711 5.11269C11.3997 5.10559 11.5284 5.09883 11.657 5.09251C11.5539 5.17316 11.4509 5.25407 11.3478 5.33524C11.3865 5.45617 11.4252 5.57719 11.4641 5.69821C11.3603 5.62779 11.2565 5.55744 11.1528 5.48736C11.0485 5.56922 10.9443 5.65135 10.84 5.73365C10.88 5.60812 10.9199 5.48268 10.9599 5.35724C10.8569 5.28777 10.754 5.21864 10.6509 5.14968C10.7781 5.14154 10.9055 5.13374 11.0326 5.12629C11.0727 5.00137 11.1128 4.87654 11.1528 4.75171Z"
						fill="white"
					/>
				</g>
				<mask id="mask59_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask59_26884_6777)">
					<path
						d="M11.1528 6.37817C11.1923 6.49807 11.2317 6.61805 11.2711 6.73803C11.3997 6.73093 11.5284 6.72417 11.657 6.71784C11.5539 6.79893 11.4509 6.88019 11.3478 6.9617C11.3865 7.08186 11.4252 7.2021 11.4641 7.32243C11.3603 7.252 11.2565 7.18165 11.1528 7.11157C11.0485 7.19344 10.9443 7.27556 10.84 7.35786C10.88 7.23311 10.9199 7.10845 10.9599 6.98371C10.8569 6.91389 10.754 6.84432 10.6509 6.77493C10.7781 6.76679 10.9055 6.75899 11.0326 6.75154C11.0727 6.62714 11.1128 6.50266 11.1528 6.37817Z"
						fill="white"
					/>
				</g>
				<mask id="mask60_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask60_26884_6777)">
					<path
						d="M12.2931 0.588867C12.3339 0.710234 12.3746 0.831688 12.4154 0.953228C12.5432 0.94829 12.6711 0.943698 12.799 0.939454C12.6954 1.01846 12.5919 1.09764 12.4884 1.17716C12.5284 1.29836 12.5684 1.41955 12.6083 1.54083C12.5033 1.46867 12.3982 1.39668 12.2931 1.32504C12.19 1.40517 12.087 1.48556 11.9838 1.56604C12.0233 1.44155 12.0629 1.31716 12.1024 1.19276C11.9986 1.12163 11.8948 1.05077 11.791 0.980169C11.9188 0.974105 12.0468 0.968301 12.1745 0.96293C12.214 0.838185 12.2536 0.713526 12.2931 0.588867Z"
						fill="white"
					/>
				</g>
				<mask id="mask61_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask61_26884_6777)">
					<path
						d="M12.2931 2.21533C12.3339 2.33601 12.3746 2.45668 12.4154 2.57735C12.5432 2.57242 12.6711 2.56782 12.799 2.56358C12.6954 2.64215 12.5919 2.72107 12.4884 2.80016C12.5284 2.92205 12.5684 3.04402 12.6083 3.166C12.5033 3.09383 12.3982 3.02193 12.2931 2.9502C12.19 3.03034 12.087 3.11073 11.9838 3.1912C12.0233 3.06603 12.0629 2.94085 12.1024 2.81576C11.9986 2.74507 11.8948 2.67455 11.791 2.6043C11.9188 2.59823 12.0468 2.59243 12.1745 2.58706C12.214 2.46318 12.2536 2.33921 12.2931 2.21533Z"
						fill="white"
					/>
				</g>
				<mask id="mask62_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask62_26884_6777)">
					<path
						d="M12.2931 3.83984C12.3339 3.96078 12.3746 4.08171 12.4154 4.20265C12.5432 4.19771 12.6711 4.19312 12.799 4.18887C12.6954 4.26788 12.5919 4.34706 12.4884 4.42658C12.5284 4.54812 12.5684 4.66966 12.6083 4.79129C12.5033 4.71913 12.3982 4.64714 12.2931 4.5755C12.19 4.65563 12.087 4.73602 11.9838 4.8165C12.0233 4.69166 12.0629 4.56692 12.1024 4.44217C11.9986 4.37105 11.8948 4.30019 11.791 4.22959C11.9188 4.22352 12.0468 4.21772 12.1745 4.21235C12.214 4.08821 12.2536 3.96407 12.2931 3.83984Z"
						fill="white"
					/>
				</g>
				<mask id="mask63_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask63_26884_6777)">
					<path
						d="M12.2931 5.46521C12.3339 5.58614 12.3746 5.70708 12.4154 5.82801C12.5432 5.82307 12.6711 5.81848 12.799 5.81424C12.6954 5.89359 12.5919 5.9732 12.4884 6.05307C12.5284 6.17392 12.5684 6.29468 12.6083 6.41561C12.5033 6.34423 12.3982 6.27302 12.2931 6.20207C12.19 6.28143 12.087 6.36104 11.9838 6.44082C12.0233 6.31677 12.0629 6.19272 12.1024 6.06875C11.9986 5.99728 11.8948 5.92607 11.791 5.85504C11.9188 5.84898 12.0468 5.84317 12.1745 5.8378C12.214 5.71349 12.2536 5.58935 12.2931 5.46521Z"
						fill="white"
					/>
				</g>
				<mask id="mask64_26884_6777" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="32" height="21">
					<path d="M-0.25293 1.16904C9.86923 -1.1125 20.3662 -1.1125 30.4883 1.16904C30.4883 7.19684 30.4883 13.2246 30.4883 19.2523C20.3662 16.9707 9.86923 16.9707 -0.25293 19.2523C-0.25293 13.2246 -0.25293 7.19684 -0.25293 1.16904Z" fill="white" />
				</mask>
				<g mask="url(#mask64_26884_6777)">
					<path
						d="M12.2931 7.09033C12.3339 7.21161 12.3746 7.33298 12.4154 7.45435C12.5432 7.44941 12.6711 7.44482 12.799 7.44057C12.6954 7.51914 12.5919 7.59798 12.4884 7.67707C12.5284 7.79861 12.5684 7.92024 12.6083 8.04178C12.5033 7.96961 12.3982 7.89771 12.2931 7.82598C12.19 7.90612 12.087 7.98651 11.9838 8.06698C12.0233 7.94215 12.0629 7.81741 12.1024 7.69266C11.9986 7.62197 11.8948 7.55154 11.791 7.48129C11.9188 7.47522 12.0468 7.46942 12.1745 7.46405C12.214 7.33948 12.2536 7.2149 12.2931 7.09033Z"
						fill="white"
					/>
				</g>
			</g>
		</svg>
	);
}

export default Languages;
