export function Etherscan({ className }) {
	return (
		<svg width="82" height="79" viewBox="0 0 82 79" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<circle cx="39.4647" cy="39.4437" r="34.2156" fill="#F4F4F4" stroke="black" strokeWidth="10" />
			<circle cx="42.6582" cy="39.2156" r="34.2156" stroke="black" strokeWidth="10" />
			<circle cx="42.6582" cy="39.2156" r="34.2156" stroke="#1B58EF" strokeWidth="5" />
			<path
				d="M29.1054 38.5034C29.1055 38.2857 29.1485 38.0702 29.2321 37.8692C29.3157 37.6682 29.4381 37.4857 29.5924 37.3321C29.7467 37.1786 29.9298 37.057 30.1312 36.9744C30.3326 36.8919 30.5483 36.8499 30.766 36.8509L33.5191 36.8598C33.958 36.8598 34.379 37.0342 34.6894 37.3446C34.9997 37.655 35.1741 38.076 35.1741 38.5149V48.9247C35.484 48.8329 35.8821 48.7347 36.3177 48.6321C36.6202 48.5611 36.8899 48.3899 37.0829 48.1464C37.2759 47.9026 37.3808 47.6011 37.3808 47.2902V34.3773C37.3808 33.9383 37.5551 33.5173 37.8655 33.2068C38.1759 32.8964 38.5969 32.722 39.0358 32.7219H41.7944C42.2334 32.722 42.6543 32.8964 42.9647 33.2068C43.2751 33.5173 43.4495 33.9383 43.4495 34.3773V46.3617C43.4495 46.3617 44.1401 46.0824 44.8129 45.7984C45.0628 45.6926 45.276 45.5157 45.426 45.2895C45.5761 45.0635 45.6562 44.7982 45.6564 44.5269V30.2396C45.6564 29.8007 45.8307 29.3798 46.141 29.0694C46.4514 28.759 46.8722 28.5846 47.3111 28.5845H50.0698C50.5087 28.5845 50.9298 28.7589 51.2399 29.0693C51.5503 29.3797 51.7249 29.8006 51.7249 30.2396V42.0048C54.1164 40.2716 56.5399 38.1871 58.4632 35.6804C58.7423 35.3166 58.9271 34.8893 59.0006 34.4368C59.0744 33.9843 59.0349 33.5205 58.8861 33.0869C57.9958 30.5254 56.5806 28.178 54.7311 26.1948C52.8816 24.2116 50.6382 22.6364 48.1449 21.5699C45.6517 20.5034 42.9632 19.9692 40.2516 20.0014C37.5399 20.0336 34.8649 20.6315 32.3976 21.7569C29.9304 22.8823 27.7252 24.5103 25.9232 26.5369C24.1213 28.5634 22.7622 30.9438 21.9331 33.5258C21.1039 36.1077 20.8229 38.8343 21.108 41.5311C21.393 44.2279 22.238 46.8355 23.5887 49.187C23.824 49.5926 24.1701 49.9226 24.5865 50.1384C25.0029 50.3542 25.4721 50.4465 25.9392 50.4048C26.461 50.359 27.1107 50.2939 27.8831 50.2033C28.2193 50.1651 28.5297 50.0046 28.7552 49.7522C28.9807 49.5 29.1055 49.1736 29.1058 48.8352V38.5034"
				fill="#0A0B0D"
			/>
			<path
				d="M29.0452 55.2001C31.9529 57.3155 35.3891 58.5851 38.9737 58.8686C42.5583 59.1523 46.1515 58.4386 49.3555 56.8069C52.5597 55.1749 55.2502 52.6882 57.1287 49.6222C59.0072 46.5562 60.0011 43.0303 60 39.4345C60 38.9858 59.9792 38.5419 59.9494 38.1006C52.8281 48.7213 39.6797 53.6867 29.0458 55.1983"
				fill="black"
			/>
		</svg>
	);
}
