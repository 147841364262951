import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import * as React from "react";
import { Line } from "../assets/line";
import hand from "../assets/hand.png";
import MickeyIco from "./MickeyIco";
import transaltions from "../language";
import { useLanguage } from "../LanguageContext";

function Dot() {
	return <div className="shrink-0 bg-black rounded-full h-[13px] w-[13px]" />;
}

function DotPair() {
	return (
		<div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
			<Dot />
			<Dot />
		</div>
	);
}

export default function SecondaryPage() {
	const { language } = useLanguage();

	const buySteps = [
		{
			image: "https://cdn.builder.io/api/v1/image/assets/TEMP/f60f85c6cbba670a6c1e46a34b537f737f503b73b5cef1ec80c4ebda5781f819?apiKey=095322ce037a477c810ff82f787f6205&",
			title: transaltions[language]["add_base_to_metamask"],
			description: transaltions[language]["download_and_install_the_metamask_wallet"],
		},
		{
			image: "https://cdn.builder.io/api/v1/image/assets/TEMP/fa4a77e9ad611f529601701d7b5cb8890a9e561d405bf576bbf36c61a0235a56?apiKey=095322ce037a477c810ff82f787f6205&",
			title: transaltions[language]["load_up_eth"],
			description: transaltions[language]["head_over_to_bridge"],
		},
		{
			image: "https://cdn.builder.io/api/v1/image/assets/TEMP/0bddb053c21e3b863260a20f39f1f450b4b145ad833e19f6a2d00a288bfca470?apiKey=095322ce037a477c810ff82f787f6205&",
			title: transaltions[language]["buy_moused"],
			description: transaltions[language]["head_over_to_uniswap"],
		},
		{
			image: "https://cdn.builder.io/api/v1/image/assets/TEMP/81ba1d98234eeffd64b6e3c979fd60145b5f4a775a4ed7851dfae4ceb82861ba?apiKey=095322ce037a477c810ff82f787f6205&",
			title: transaltions[language]["add_moused_to_your_wallet"],
			description: transaltions[language]["now_all_you_have_to_do"],
		},
	];

	const carroussel = [
		{
			text: <>{transaltions[language]["with_nothing_but_a_bald_head_and_a_dream_long"]}</>,
			img: "https://i.imgur.com/KX3lzjl.png",
		},
		{
			text: <>{transaltions[language]["moused_catches_on_like_wildfire"]}</>,
			img: "https://i.imgur.com/nA410fO.png",
		},
		{
			text: <>{transaltions[language]["as_moused_skyrockets"]}</>,
			img: "https://i.imgur.com/8skDEKe.png",
		},
		{
			text: <>{transaltions[language]["mickeys_story_spreads_far_and_wide"]}</>,
			img: "https://i.imgur.com/hmCrqUi.png",
		},
	];

	const [index, setIndex] = React.useState(0);

	const handleNext = () => {
		if (index === carroussel.length - 1) return;
		setIndex(index + 1);
	};

	const handlePrev = () => {
		if (index === 0) return;
		setIndex(index - 1);
	};

	return (
		<div className="flex flex-col bg-violet-200 mt-[8vh]">
			<div className="text-left my-10">
				<h2 className="font-Mickey text-8xl text-black ml-[15vw] max-md:ml-[5vw] max-sm:ml-[8vw] max-md:text-6xl max-sm:text-4xl text-left">{transaltions[language]["oh_boy_i_got_moused"]}</h2>
				{/* <span className=" ml-[15vw] max-md:ml-[5vw] max-sm:ml-[8vw] ">In suscipit diam vitae malesuada ultricies</span> */}
			</div>
			<div className="comicsMickey">
				<div key={index} className=" flex flex-col justify-center h-[70vh] comic-neue-bold py-[3vh] flex-shrink-0">
					<div className="flex flex-col self-center justify-around w-[70%] max-md:w-[90%] border-black h-[70vh] max-md:h-[50vh] py-[3vh] px-8 rounded-3xl border-4 border-solid shadow-sm bg-slate-600 max-md:px-5">
						<DotPair />
						<div className="flex flex-row justify-center">
							<MickeyIco den={carroussel.length} num={index + 1} />
						</div>
						<div className="flex-grow flex flex-col justify-between">
							<p className="text-[1.7vw] text-white px-[6vw] max-xl:text-lg max-lg:text-base max-sm:text-sm max-lg:px-[0vw] self-center mt-5">{carroussel[index].text}</p>
							<div className="flex flex-row justify-between items-end px-[6vw]">
								<button type="button" onClick={handlePrev} className="self-end">
									<img className={`hand shrink-0 aspect-square  ${index === 0 ? "opacity-0 cursor-default" : "opacity-100"}`} src={hand} alt="hand right" />
								</button>
								<img loading="lazy" className="imgmickey" src={carroussel[index].img} alt="hands mickey" />
								<button type="button" onClick={handleNext} className="self-end">
									<img className={`hand shrink-0 aspect-square scale-x-[-1] ${index === carroussel.length - 1 ? "opacity-0 cursor-default" : "opacity-100"}`} src={hand} alt="hand left" />
								</button>
							</div>
						</div>
						<DotPair />
					</div>
				</div>
			</div>
			<section className="flex z-10 flex-col px-10 mt-[10vh] mb-[10vh] w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
				<div className="max-md:ml-0 max-lg:ml-2 max-xl:ml-12 ml-24 max-w-full w-[100%]">
					<div className="flex gap-5 flex-row max-md:gap-0 h-full">
						<h2 className="font-Mickey my-auto text-8xl text-black  max-md:text-6xl max-sm:text-4xl text-left">{transaltions[language]["how_to_buy"]}</h2>
						<div className="flex flex-col ml-5 w-[19%] max-md:ml-0">
							<img
								loading="lazy"
								src="https://cdn.builder.io/api/v1/image/assets/TEMP/669b24120ed48a7235e37010c33c027f1ef2fea03bd220c129db02acf36ab5d6?apiKey=095322ce037a477c810ff82f787f6205&"
								alt=""
								className="shrink-0 w-28 max-w-full aspect-square"
							/>
						</div>
					</div>
				</div>
				<div className="self-center mt-10 max-w-full max-md:mt-0 parent max-md:h-auto px-[2vw] max-lg:px-0">
					<img
						loading="lazy"
						src="https://cdn.builder.io/api/v1/image/assets/TEMP/cdf0064f9cc9e6bb874f6716eeb743b884bbad4f97190e3feab8aa77dd9c261e?apiKey=095322ce037a477c810ff82f787f6205&"
						alt={"cool bald mickey lmao"}
						className={"grow max-w-full aspect-[0.78] w-[250px] div5"}
					/>
					{buySteps.map((step, index) => (
						<BuyStep key={index} index={index} {...step} />
					))}
				</div>
			</section>
		</div>
	);
}

function BuyStep({ index, image, title, description }) {
	const div = "div" + (index + 1);
	return (
		<div className={`dropshadow justify-center min-h-fit p-8 max-md:p-6 max-w-full border-black border-solid bg-zinc-100 max-xl:w-[80vw] border-b-[10px] border-l-[10px] border-r-[3px] border-t-[3px] rounded-[42px] w-auto h-fit max ${div}`}>
			<div className="flex gap-6 h-full">
				<div className="flex flex-col max-md:hidden">
					<img loading="lazy" src={image} alt="" className="shrink-0 self-stretch my-auto max-w-full aspect-square w-[190px] max-md:mt-10" />
				</div>
				<Line />
				<div className="flex flex-col ml-5 max-md:ml-3 w-full ">
					<div className="flex flex-col justify-center h-full py-px text-black">
						<h2 className="text-[2vw] font-Mickey text-left max-lg:text-4xl max-md:text-3xl max-xl:text-3xl max-md:leading-0">{title}</h2>
						{description && <p className="comic-neue-bold mt-3 max-md:mt-0 text-xl text-left max-lg:text-base max-sm:text-sm">{description}</p>}
					</div>
				</div>
			</div>
		</div>
	);
}
