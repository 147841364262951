export function Telegram({ className }) {
	return (
		<svg width="83" height="79" viewBox="0 0 83 79" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<circle cx="39.8401" cy="39.4437" r="34.2156" fill="#F4F4F4" stroke="black" strokeWidth="10" />
			<circle cx="43.0336" cy="39.2156" r="34.2156" stroke="black" strokeWidth="10" />
			<circle cx="43.0336" cy="39.2156" r="34.2156" stroke="#1B58EF" strokeWidth="5" />
			<path
				d="M26.8392 36.8029C26.8392 36.8029 40.1075 31.3576 44.7092 29.4402C46.4732 28.6733 52.4554 26.219 52.4554 26.219C52.4554 26.219 55.2165 25.1453 54.9864 27.7529C54.9096 28.8267 54.2961 32.5847 53.6826 36.6495C52.7622 42.4017 51.7652 48.6907 51.7652 48.6907C51.7652 48.6907 51.6118 50.4547 50.308 50.7615C49.0042 51.0682 46.8566 49.6878 46.4732 49.3809C46.1664 49.1509 40.7211 45.6996 38.727 44.0123C38.1901 43.5521 37.5766 42.6318 38.8036 41.5581C41.5647 39.0271 44.8626 35.8826 46.8566 33.8886C47.777 32.9682 48.6973 30.8207 44.8626 33.4283C39.4173 37.1864 34.0486 40.7144 34.0486 40.7144C34.0486 40.7144 32.8214 41.4813 30.5206 40.791C28.2197 40.1009 25.5353 39.1805 25.5353 39.1805C25.5353 39.1805 23.6948 38.0301 26.8392 36.8029Z"
				fill="black"
			/>
		</svg>
	);
}
