import React from "react";
function MickeyIco({ den, num }) {
	return (
		<svg
			width="100"
			height="70"
			viewBox="0 0 87 67"
			fill="none"
			style={{
				width: "10vw",
				height: "7vw",
				maxWidth: "170px",
				maxHeight: "120px",
			}}
			className=" w-full text-3xl max-lg:text-2xl max-md:text-xl max-sm:text-base text-white font-Waltograph"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="44.5" cy="42.5" r="24.5" fill="black" />
			<circle cx="18" cy="18" r="18" fill="black" />
			<circle cx="69" cy="18" r="18" fill="black" />
			<text textAnchor="middle" x="44.5" y="50" fill="white" fontSize="20">
				{num}/{den}
			</text>
		</svg>
	);
}

export default MickeyIco;
