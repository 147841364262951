import React from "react";
import { contract } from "../utils/links";

export default function AddressInput() {
	return (
		<div className="py-1 pr-2 pl-4 max-md:mt-0 text-base text-black whitespace-nowrap border-black border-solid bg-zinc-100 border-[3px] rounded-[30px] max-md:w-[80%] max-sm:px-[2vw] max-md:text-sm max-sm:text-xs">
			<div className="flex gap-4 justify-between max-md:w-auto">
				<p className="my-auto overflow-hidden overflow-ellipsis whitespace-nowrap inline-block ">{contract}</p>
				<button
					className="justify-center px-3 py-2 font-bold bg-yellow-500 border-black border-solid border-[3px] rounded-[30px] text-base max-md:text-xs hover:bg-yellow-400 transition-all duration-300"
					onClick={() => {
						navigator.clipboard.writeText(contract);
						alert("Contract address " + contract + " copied to clipboard!");
					}}
				>
					Copy
				</button>
			</div>
		</div>
	);
}
