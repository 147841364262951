import "./App.css";
import MainPage, { Header } from "./components/MainPage";
import SecondaryPage from "./components/SecondaryPage";
import AboutUs from "./components/ThirdPage";
import FourthPage from "./components/FourthPage";
import Footer from "./components/Footer";

function App() {
	return (
		<div className="App">
			<Header />
			<MainPage />
			<SecondaryPage />
			<AboutUs />
			<FourthPage />
			<Footer />
		</div>
	);
}

export default App;
