export const links = {
	aboutUs: "https://www.google.com",
	tokenomics: "https://www.google.com",
	community: "https://www.google.com",
	buy: "https://www.google.com",
	x: "https://twitter.com/MousedOnBase",
	telegram: "https://www.google.com",
	gecko: "https://www.google.com",
	etherscan: "https://www.google.com",
	dextools: "https://www.google.com",
};

export const contract = "0x000000000000000000000000000000000000000";
