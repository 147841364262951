import React, { useState } from "react";
import { useLanguage } from "../LanguageContext";
import Languages from "./Languages";

export function ShareBTN({ shownOnMobile = false }) {
	return (
		<div
			className={`rect1 self-center hoverShare relative z-30 ${shownOnMobile ? "hidden max-md:flex" : "flex max-md:hidden"}`}
			onClick={() => {
				window.open("https://twitter.com/intent/post?text=%23moused%20is%20set%20to%20revolutionize%20the%20world%20of%20%23memecoin%2C%20I%20was%20too%20cool%20for%20%40Disney%20anyway%21%20Get%20my%20cheese", "_blank");
			}}
		>
			<span className="languages-text comic-neue-bold">Share</span>
			<svg width="30" height="30" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="z-30 relative">
				<path d="M0.905762 7.22384C3.6546 3.85862 7.94966 1.65133 12.7172 1.14474L14.0486 1L21.9945 12.6154" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path
					d="M9.41024 9.39498L22.0377 12.6155V16.0892C21.0069 16.0892 20.1908 16.7767 20.1908 17.6452C20.1908 18.5136 21.0069 19.2011 22.0377 19.2011V22.1321L8.55123 18.6946C8.59418 18.586 8.59418 18.4774 8.59418 18.3327C8.59418 17.4643 7.77812 16.7767 6.74731 16.7767C5.9742 16.7767 5.28699 17.211 5.02928 17.7899L0.948975 16.7406V7.22388L5.71649 8.45417"
					stroke="white"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.767 17.3557C16.8819 17.3557 17.7856 16.5943 17.7856 15.655C17.7856 14.7158 16.8819 13.9543 15.767 13.9543C14.6521 13.9543 13.7483 14.7158 13.7483 15.655C13.7483 16.5943 14.6521 17.3557 15.767 17.3557Z"
					stroke="white"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.52041 10.6614C8.61157 10.6614 9.49613 9.9162 9.49613 8.99691C9.49613 8.07763 8.61157 7.3324 7.52041 7.3324C6.42924 7.3324 5.54468 8.07763 5.54468 8.99691C5.54468 9.9162 6.42924 10.6614 7.52041 10.6614Z"
					stroke="white"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.4405 14.3522C11.0098 14.3522 11.4713 13.9634 11.4713 13.4838C11.4713 13.0042 11.0098 12.6154 10.4405 12.6154C9.87118 12.6154 9.40967 13.0042 9.40967 13.4838C9.40967 13.9634 9.87118 14.3522 10.4405 14.3522Z"
					stroke="white"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M4.47112 14.678C5.20647 14.678 5.80259 14.1758 5.80259 13.5563C5.80259 12.9368 5.20647 12.4346 4.47112 12.4346C3.73577 12.4346 3.13965 12.9368 3.13965 13.5563C3.13965 14.1758 3.73577 14.678 4.47112 14.678Z"
					stroke="white"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

function HeaderButtons() {
	const [isDropdownVisible, setDropdownVisible] = useState(false);
	const { language, switchLanguage } = useLanguage(); // Access the current language and function to switch languages

	return (
		<div className="flex flex-row gap-2">
			<ShareBTN />
			<div className="rect1 self-center relative" onMouseEnter={() => setDropdownVisible(true)} onMouseLeave={() => setDropdownVisible(false)}>
				<Languages language={language} />
				<div className={`languages-dropdown absolute top-full -mt-4  left-0 bg-[#212121] bg-opacity-85 comic-neue-regular text-white ${isDropdownVisible ? "visible" : "hidden"}`}>
					<div className="mt-2.5"> </div>
					<div onClick={() => switchLanguage("EN")} className={`flex flex-row justify-center py-2 ${language === "EN" ? "hidden" : ""}`}>
						<Languages language={"EN"} />
					</div>
					<div onClick={() => switchLanguage("CN")} className={`flex flex-row justify-center py-2 ${language === "CN" ? "hidden" : ""}`}>
						<Languages language={"CN"} />
					</div>
					<div onClick={() => switchLanguage("JP")} className={`flex flex-row justify-center py-2 ${language === "JP" ? "hidden" : ""}`}>
						<Languages language={"JP"} />
					</div>
					<div onClick={() => switchLanguage("KR")} className={`flex flex-row justify-center py-2 ${language === "KR" ? "hidden" : ""}`}>
						<Languages language={"KR"} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default HeaderButtons;
