const transaltions = {
	EN: {
		about_us: "About us",
		tokenomics: "Tokenomics",
		community: "Community",
		buy_now: "Buy Now",
		share: "Share",
		with_nothing_but_a_bald_head_and_a_dream: "With nothing but a bald head and a dream if you have been moused before, you're one of us!",
		getting_moused_became_a_badge_of_honour: 'Getting "moused" became a badge of honour, binding the community together. "Got moused? You will make it!"',
		built_on: "Built on",
		oh_boy_i_got_moused: "OH BOY, I GOT $MOUSED!",
		with_nothing_but_a_bald_head_and_a_dream_long:
			"With nothing but a bald head and a dream, Bald Mickey Moused dives into the Base blockchain, ready to shake things up. \"If you've been moused, you're one of us,\" he declares, launching $MOUSED into the wild. It's not just a coin; it's a symbol of turning luck around, of finding fortune in the face of failure.",
		moused_catches_on_like_wildfire:
			'$MOUSED catches on like wildfire. It\'s hilarious, it\'s relatable, and above all, it\'s a rallying point for the crypto community. Getting "moused" becomes a badge of honor, a shared experience that binds the community together. "Got moused? You will make it!" becomes the chant echoing in forums and chat rooms, a reminder that every loss is just a step on the path to success.',
		as_moused_skyrockets:
			"As $MOUSED skyrockets, Mickey's bald head becomes an emblem of prosperity. He could've gone for that hair transplant, but why mess with a winning formula? Instead, he reinvests his gains into the community, funding projects and supporting fellow traders who've been moused just like him.",
		mickeys_story_spreads_far_and_wide:
			"Mickey's story spreads far and wide, inspiring a movement of resilience and humor in the face of adversity. $MOUSED stickers, memes, and merch flood the crypto space, each one a testament to the spirit of never giving up. And Mickey, forever the bald beacon of hope, continues to lead the charge, proving that getting moused isn't the end—it's just the beginning of making it big.",
		how_to_buy: "How to buy",
		add_base_to_metamask: "ADD BASE TO METAMASK",
		download_and_install_the_metamask_wallet: "Download & install the Metamask Wallet either from the app store on your phone or the browser extension for desktop.",
		load_up_eth: "LOAD UP ETH",
		head_over_to_bridge: "Head over to bridge.base.org, connect your wallet on the Ethereum blockchain. Choose how much ETH you would like to bridge from the erc20 network to Base.",
		buy_moused: (
			<span>
				<span>
					BUY <span className="text-blue-600">$MOUSED</span>
				</span>
			</span>
		),
		what_does_the_community_look_like: (
			<>
				What is <span className="text-blue-600">$MOUSED</span> on?
			</>
		),
		why: "Who's the team?",
		team: "The team comprises award-winning designers & illustrators, seasoned on-chain veterans, and developers who are truly 'based'. Each member brings a unique set of skills and experiences, making our collective truly exceptional in the blockchain space.",
		head_over_to_uniswap: (
			<>
				Head over to{" "}
				<a href="https://app.uniswap.org/swap" target="_blank" className="text-blue-600">
					{" "}
					Uniswap
				</a>{" "}
				and paste the $MOUSED contract Address listed on this website to swap your ETH.
			</>
		),
		add_moused_to_your_wallet: (
			<span>
				ADD <span className="text-blue-600">$MOUSED</span> TO YOUR WALLET
			</span>
		),
		now_all_you_have_to_do: "Now all you have to do is add the $MOUSED contract address to your Metamask Wallet for your $MOUSED tokens to show. Welcome aboard!",
		who_is_bald_mickey_mouse: (
			<>
				WHO IS <span className="text-blue-600">bald mickey mouse</span>?
			</>
		),
		moused_isnt_just_a_coin:
			"$MOUSED isn't just a coin; it's a lifestyle. It's for the bold, the bald, and everyone who's ever been knocked down but got back up with a grin. Because in the world of crypto, getting moused is just the first step to making your fortune.",
		why_base_chain: "Why Base Chain?",
		we_recognize_the_absence: "We recognize the absence of a significant foundational meme coin on Base. Our goal is to address this gap by introducing a new standard in this category.",
		whos_the_team: "Who's the team?",
		backed_by_wif_whales: "Backed by WIF whales",
		awarded_design_team: "Awarded Design team",
		marketing_led_by_a_top_20_blockchain_og: "Marketing led by a top 20 blockchain OG.",
		we_love_the_cheese: "We love the cheese",
		moused_tokenomics: "$MOUSED TOKENOMICS",
		the_tokenomics_of_moused: "The tokenomics of $MOUSED are designed to reward early adopters and incentivize long-term holders.",
		supply_breakdown: "Supply breakdown",
		total_supply: "Total Supply: 6.9 Billion Tokens",
		"50_percent_of_supply_to_presale": "50% of Supply to Presale Participants",
		"25_percent_of_supply_to_lp": "25% of Supply to LP",
		"25_percent_marketing_and_cexs": "25% Marketing & CEXs",
		presale_breakdown: "Presale breakdown",
		"75_percent_of_raise_to_lp": "75% of Raise to LP",
		"25_percent_of_raise_for_opex": "25% of Raise for OPEX",
		built_on_copyright: "Built on",
		copyright_all_rights_reserved: "Copyright ©2024. All rights reserved.",
		trade_on: "Trade on",
	},
	CN: {
		about_us: "关于我们",
		tokenomics: "代币经济学",
		community: "社区",
		buy_now: "立即购买",
		share: "分享",
		with_nothing_but_a_bald_head_and_a_dream: "如果你曾经被 'moused'，你就是我们的一员！",
		getting_moused_became_a_badge_of_honour: "被'moused'成为荣誉的象征，将社区紧密地绑定在一起。'被moused了？你会成功的！'",
		built_on: "建立在",
		oh_boy_i_got_moused: "哦，伙计，我得到了$MOUSED!",
		with_nothing_but_a_bald_head_and_a_dream_long:
			"只有一个秃头和一个梦想，秃头米奇冲进了Base区块链，准备搅动风云。'如果你被moused了，你就是我们的一部分，'他宣布，将$MOUSED投放到野外。这不仅仅是一个币；它是一个标志，象征着逆转运气，在失败面前找到财富。",
		moused_catches_on_like_wildfire:
			"$MOUSED迅速流行起来。它既搞笑，又令人共鸣，最重要的是，它成为加密社区的一个聚集点。被'moused'成为一种荣誉徽章，一个共享的经验，将社区紧密地绑定在一起。'被moused了？你会成功的！'成为论坛和聊天室中回荡的呼声，提醒每一个损失只是成功道路上的一步。",
		as_moused_skyrockets: "随着$MOUSED的价值飙升，米奇的秃头成为繁荣的象征。他本可以选择做头发移植，但为什么要打乱赢的配方呢？相反，他将收益重新投资于社区，资助项目和支持像他一样被moused的交易者。",
		mickeys_story_spreads_far_and_wide:
			"米奇的故事广为传播，激励了一场面对逆境时的韧性和幽默运动。$MOUSED的贴纸、模因和商品充斥着加密空间，每一个都证明了永不放弃的精神。而米奇，作为希望的秃头灯塔，继续带领着大家前行，证明被moused不是终点——它只是大有作为的开始。",
		how_to_buy: "如何购买",
		add_base_to_metamask: "将BASE添加到METAMASK",
		download_and_install_the_metamask_wallet: "从手机的应用商店下载并安装Metamask钱包，或者为桌面下载浏览器扩展。",
		load_up_eth: "装载ETH",
		head_over_to_bridge: "前往bridge.base.org，连接你的以太坊区块链钱包。选择你希望从erc20网络桥接到Base的ETH数量。",
		buy_moused: (
			<span>
				<span>
					购买 <span className="text-blue-600">$MOUSED</span>
				</span>
			</span>
		),
		what_does_the_community_look_like: (
			<>
				<span className="text-blue-600">$MOUSED</span> 是什么样的？
			</>
		),
		team: "团队由获奖设计师和插画师、经验丰富的链上老兵和真正'基础'的开发人员组成。每个成员都带来了独特的技能和经验，使我们的集体在区块链空间中真正卓越。",
		why: "谁是团队？",
		head_over_to_uniswap: (
			<>
				前往{" "}
				<a href="https://app.uniswap.org/swap" target="_blank" className="text-blue-600">
					{" "}
					Uniswap
				</a>{" "}
				并粘贴本网站列出的$MOUSED合约地址来交换你的ETH。
			</>
		),
		add_moused_to_your_wallet: (
			<span>
				将 <span className="text-blue-600">$MOUSED</span> 添加到你的钱包
			</span>
		),
		now_all_you_have_to_do: "现在你所需要做的就是将$MOUSED合约地址添加到你的Metamask钱包，你的$MOUSED代币就会显示出来。欢迎加入！",
		who_is_bald_mickey_mouse: (
			<>
				谁是 <span className="text-blue-600">秃头米奇老鼠</span>?
			</>
		),
		moused_isnt_just_a_coin: "$MOUSED不仅仅是一枚硬币；它是一种生活方式。它适合于那些勇敢的人，那些秃头的人，以及每一个被击倒却带着微笑站起来的人。因为在加密世界里，被moused只是赚取财富的第一步。",
		why_base_chain: "为什么是Base链?",
		we_recognize_the_absence: "我们认识到在Base上缺少一个重要的基础梗币。我们的目标是通过引入一个新标准来填补这一空白。",
		whos_the_team: "团队是谁?",
		backed_by_wif_whales: "由WIF鲸鱼支持",
		awarded_design_team: "获奖设计团队",
		marketing_led_by_a_top_20_blockchain_og: "由前20位区块链原创领导的营销。",
		we_love_the_cheese: "我们爱奶酪",
		moused_tokenomics: "$MOUSED代币经济学",
		the_tokenomics_of_moused: "$MOUSED的代币经济学旨在奖励早期采纳者并激励长期持有者。",
		supply_breakdown: "供应分解",
		total_supply: "总供应量: 69亿代币",
		"50_percent_of_supply_to_presale": "50%的供应量给予预售参与者",
		"25_percent_of_supply_to_lp": "25%的供应量给予流动性提供者",
		"25_percent_marketing_and_cexs": "25%的供应量用于营销和中心化交易所",
		presale_breakdown: "预售分解",
		"75_percent_of_raise_to_lp": "75%的筹资给予流动性提供者",
		"25_percent_of_raise_for_opex": "25%的筹资用于运营支出",
		built_on_copyright: "版权所有",
		copyright_all_rights_reserved: "版权 ©2024. 保留所有权利。",
		trade_on: "交易于",
	},
	JP: {
		about_us: "私たちについて",
		tokenomics: "トークノミクス",
		community: "コミュニティ",
		buy_now: "今すぐ購入",
		share: "共有する",
		with_nothing_but_a_bald_head_and_a_dream: "もしもあなたが「マウスド」されたことがあるなら、あなただけの夢と禿頭で、あなたも私たちの一員です！",
		getting_moused_became_a_badge_of_honour: "「マウスド」されることが名誉のバッジとなり、コミュニティを一つに結びつけます。「マウスドされた？あなたは成功するでしょう！」",
		built_on: "構築済み",
		oh_boy_i_got_moused: "おや、私は$MOUSEDを手に入れた！",
		with_nothing_but_a_bald_head_and_a_dream_long:
			"夢と禿頭だけを持って、バルドミッキーマウスドはBaseブロックチェーンに飛び込み、物事を揺さぶります。「もしマウスドされたなら、あなたは私たちの一員です」と彼は宣言し、$MOUSEDを野生に放ちます。これは単なるコインではなく、運を変える象徴、失敗の中で財産を見つける象徴です。",
		moused_catches_on_like_wildfire:
			"$MOUSEDは野火のように広がります。それはユーモラスで、共感を呼び、何よりも、それは暗号コミュニティの集結点です。「マウスドされましたか？あなたは成功するでしょう！」は、フォーラムやチャットルームで反響する合言葉となり、あらゆる損失は成功への道の一歩に過ぎないことを思い出させます。",
		as_moused_skyrockets:
			"$MOUSEDの価値が高騰するにつれて、ミッキーの禿頭は繁栄の象徴となります。彼はその髪の移植を受けることができましたが、なぜ勝利の公式を台無しにするのでしょうか？代わりに、彼はコミュニティに再投資し、プロジェクトを資金提供し、彼のようにマウスドされた仲間のトレーダーを支援します。",
		mickeys_story_spreads_far_and_wide:
			"ミッキーの物語は広く伝わり、逆境に直面しても韧性とユーモアを持って前進する運動を触発します。$MOUSEDのステッカー、ミーム、そして商品が暗号空間を溢れかえり、それぞれが決して諦めない精神の証となります。そして、ミッキーは希望の禿げた灯台として、マウスドされることが終わりではなく、大きくなるための始まりに過ぎないことを証明し続けるでしょう。",
		how_to_buy: "購入方法",
		add_base_to_metamask: "METAMASKにBASEを追加",
		download_and_install_the_metamask_wallet: "スマホのアプリストアまたはデスクトップのブラウザ拡張機能からMetamaskウォレットをダウンロードしてインストールしてください。",
		load_up_eth: "ETHをロード",
		head_over_to_bridge: "bridge.base.orgにアクセスし、Ethereumブロックチェーン上のあなたのウォレットを接続してください。erc20ネットワークからBaseにブリッジしたいETHの量を選択してください。",
		buy_moused: (
			<span>
				<span>
					購入 <span className="text-blue-600">$MOUSED</span>
				</span>
			</span>
		),
		head_over_to_uniswap: (
			<>
				{" "}
				<a href="https://app.uniswap.org/swap" target="_blank" className="text-blue-600">
					{" "}
					Uniswap
				</a>{" "}
				にアクセスし、このウェブサイトにリストされている$MOUSEDのコントラクトアドレスを貼り付けてETHを交換してください。
			</>
		),
		what_does_the_community_look_like: (
			<>
				<span className="text-blue-600">$MOUSED</span>はどのようなものですか?
			</>
		),
		team: "チームは、受賞歴のあるデザイナーやイラストレーター、経験豊富なオンチェーンのベテラン、そして本当に「ベース」した開発者で構成されています。各メンバーは独自のスキルと経験を持ち寄り、私たちの集団をブロックチェーン空間で真に特別なものにしています。",
		why: "チームは誰ですか？",
		add_moused_to_your_wallet: (
			<span>
				<span className="text-blue-600">$MOUSED</span> をあなたのウォレットに追加
			</span>
		),
		now_all_you_have_to_do: "あとは$MOUSEDのコントラクトアドレスをあなたのMetamaskウォレットに追加するだけで、$MOUSEDトークンが表示されます。ようこそ！",
		who_is_bald_mickey_mouse: (
			<>
				誰が <span className="text-blue-600">禿げたミッキーマウス</span> ですか?
			</>
		),
		moused_isnt_just_a_coin:
			"$MOUSEDはただのコインではありません；それはライフスタイルです。それは大胆な人、禿げた人、そして何度も倒されても笑顔で立ち上がった全ての人のためのものです。なぜなら、暗号の世界では、マウスドされることは財産を築く最初のステップに過ぎないからです。",
		why_base_chain: "なぜBaseチェーンか?",
		we_recognize_the_absence: "私たちはBase上に重要な基礎的なミームコインの不在を認識しています。私たちの目標は、このカテゴリーに新しい基準を導入することでこのギャップを埋めることです。",
		whos_the_team: "チームは誰ですか?",
		backed_by_wif_whales: "WIFのクジラに支えられています",
		awarded_design_team: "受賞デザインチーム",
		marketing_led_by_a_top_20_blockchain_og: "トップ20ブロックチェーンOGによって率いられるマーケティング。",
		we_love_the_cheese: "私たちはチーズが大好きです",
		moused_tokenomics: "$MOUSEDトークノミクス",
		the_tokenomics_of_moused: "$MOUSEDのトークノミクスは、早期採用者を報酬し、長期保有者を奨励するように設計されています。",
		supply_breakdown: "供給の内訳",
		total_supply: "総供給量: 69億トークン",
		"50_percent_of_supply_to_presale": "供給量の50％をプレセール参加者に",
		"25_percent_of_supply_to_lp": "供給量の25％をLPに",
		"25_percent_marketing_and_cexs": "供給量の25％をマーケティングとCEXに",
		presale_breakdown: "プレセールの内訳",
		"75_percent_of_raise_to_lp": "調達額の75％をLPに",
		"25_percent_of_raise_for_opex": "調達額の25％を運営費用に",
		built_on_copyright: "権利情報",
		copyright_all_rights_reserved: "著作権 ©2024. すべての権利を保有。",
		trade_on: "取引先",
	},
	KR: {
		about_us: "우리에 대하여",
		tokenomics: "토큰 경제",
		community: "커뮤니티",
		buy_now: "지금 구입하기",
		share: "공유하기",
		with_nothing_but_a_bald_head_and_a_dream: "대머리 머리와 꿈만 가지고 있어도, 당신은 우리 중 한 명입니다!",
		getting_moused_became_a_badge_of_honour: '"마우스드"가 되는 것은 영광의 표시가 되어, 커뮤니티를 하나로 묶습니다. "마우스드 됐어? 성공할 거야!"',
		built_on: "기반",
		oh_boy_i_got_moused: "오, 난 $MOUSED를 얻었어!",
		with_nothing_but_a_bald_head_and_a_dream_long:
			'대머리 머리와 꿈만 가지고, Bald Mickey Moused는 Base 블록체인으로 뛰어들어 큰 변화를 일으키려 합니다. "마우스드가 된 적이 있다면, 당신도 우리 중 하나,"라고 선언하며 $MOUSED를 출시합니다. 이것은 단순한 코인이 아니라, 운이 나빠 보일 때 그 운을 돌리는 상징, 실패한 얼굴에 재산을 찾는 상징입니다.',
		moused_catches_on_like_wildfire:
			'"$MOUSED는 급속도로 인기를 끕니다. 재미있고, 공감 가며, 무엇보다 모든 크립토 커뮤니티의 결집점입니다. "마우스드"가 되는 것은 영광의 표시, 커뮤니티를 하나로 묶는 공통된 경험이 됩니다. "마우스드 됐어? 성공할 거야!"는 포럼과 채팅방에서 울려퍼지는 구호가 되며, 모든 손실이 성공으로 가는 길 위의 한 걸음일 뿐임을 상기시킵니다.',
		as_moused_skyrockets:
			"$MOUSED의 가치가 치솟으면서, Mickey의 대머리는 번영의 상징이 됩니다. 그는 그의 머리에 이식을 할 수 있었지만, 왜 이기는 공식을 바꿀까요? 대신, 그는 자신의 이익을 커뮤니티에 재투자하여, 그와 같이 마우스드가 된 동료 트레이더들을 지원하고 프로젝트에 자금을 제공합니다.",
		mickeys_story_spreads_far_and_wide:
			"Mickey의 이야기는 멀리 퍼져, 역경에 맞서는 탄력성과 유머의 운동을 영감으로 합니다. $MOUSED 스티커, 밈, 상품들이 크립토 공간을 넘쳐나며, 포기하지 않는 정신을 증명하는 각각의 증거가 됩니다. 그리고 Mickey는 계속해서 희망의 대머리 등대로, 마우스드가 끝이 아니라 크게 성공하기 위한 시작일 뿐임을 증명하면서, 선두를 달립니다.",
		how_to_buy: "구매 방법",
		add_base_to_metamask: "메타마스크에 BASE 추가",
		download_and_install_the_metamask_wallet: "앱 스토어 또는 데스크탑용 브라우저 확장 프로그램에서 메타마스크 지갑을 다운로드 및 설치하세요.",
		load_up_eth: "ETH 충전",
		head_over_to_bridge: "bridge.base.org으로 이동하여 이더리움 블록체인에 지갑을 연결하세요. erc20 네트워크에서 Base로 얼마나 많은 ETH를 브리지할지 선택하세요.",
		buy_moused: (
			<span>
				<span>
					<span className="text-blue-600">$MOUSED</span> 구입하기
				</span>
			</span>
		),
		what_does_the_community_look_like: (
			<>
				<span className="text-blue-600">$MOUSED</span>는 어떤 모습인가요?
			</>
		),
		team: "팀은 수상 경력이 있는 디자이너 및 일러스트레이터, 경력이 많은 온체인 전문가 및 정말 '기반'을 둔 개발자들로 구성되어 있습니다. 각 구성원은 독특한 기술과 경험을 가지고 있어, 우리의 집단은 블록체인 분야에서 정말로 탁월합니다.",
		why: "팀은 누구인가요?",
		head_over_to_uniswap: (
			<>
				<a href="https://app.uniswap.org/swap" target="_blank" className="text-blue-600">
					Uniswap
				</a>
				으로 이동하여 이 웹사이트에 나열된 $MOUSED 계약 주소를 붙여넣어 ETH를 스왑하세요.
			</>
		),
		add_moused_to_your_wallet: (
			<span>
				지갑에 <span className="text-blue-600">$MOUSED</span> 추가하기
			</span>
		),
		now_all_you_have_to_do: "이제 해야 할 모든 것은 메타마스크 지갑에 $MOUSED 계약 주소를 추가하는 것입니다. 당신의 $MOUSED 토큰이 표시될 것입니다. 환영합니다!",
		who_is_bald_mickey_mouse: <>대머리 미키 마우스는 누구인가요?</>,
		moused_isnt_just_a_coin:
			"$MOUSED는 단순한 코인이 아닙니다; 그것은 라이프스타일입니다. 그것은 대담한 사람들, 대머리들, 그리고 넘어져도 웃으며 다시 일어난 모든 사람을 위한 것입니다. 왜냐하면 크립토 세계에서 마우스드가 되는 것은 당신의 재산을 만드는 첫 걸음일 뿐이기 때문입니다.",
		why_base_chain: "왜 Base 체인인가?",
		we_recognize_the_absence: "우리는 Base에 중요한 기초 밈 코인의 부재를 인식합니다. 우리의 목표는 이 카테고리에서 새로운 표준을 도입함으로써 이 격차를 해소하는 것입니다.",
		whos_the_team: "팀은 누구인가요?",
		backed_by_wif_whales: "WIF 고래들에 의해 지원됨",
		awarded_design_team: "수상 경력이 있는 디자인 팀",
		marketing_led_by_a_top_20_blockchain_og: "상위 20 블록체인 OG가 이끄는 마케팅.",
		we_love_the_cheese: "우리는 치즈를 사랑합니다",
		moused_tokenomics: "$MOUSED 토큰 경제",
		the_tokenomics_of_moused: "$MOUSED의 토큰 경제는 초기 채택자들에게 보상하고 장기 보유자들을 장려하도록 설계되었습니다.",
		supply_breakdown: "공급 분해",
		total_supply: "총 공급량: 69억 토큰",
		"50_percent_of_supply_to_presale": "공급량의 50%를 사전 판매 참여자에게",
		"25_percent_of_supply_to_lp": "공급량의 25%를 LP에",
		"25_percent_marketing_and_cexs": "25% 마케팅 & CEXs",
		presale_breakdown: "사전 판매 분해",
		"75_percent_of_raise_to_lp": "모금액의 75%를 LP에",
		"25_percent_of_raise_for_opex": "모금액의 25%를 OPEX에",
		built_on_copyright: "기반",
		copyright_all_rights_reserved: "저작권 ©2024. 모든 권리 보유.",
		trade_on: "거래하기",
	},
};

export default transaltions;
