export function X({ className }) {
	return (
		<svg width="82" height="79" viewBox="0 0 82 79" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<circle cx="39.2156" cy="39.4437" r="34.2156" fill="#F4F4F4" stroke="black" strokeWidth="10" />
			<circle cx="42.409" cy="39.2156" r="34.2156" stroke="black" strokeWidth="10" />
			<circle cx="42.409" cy="39.2156" r="34.2156" stroke="#1B58EF" strokeWidth="5" />
			<path
				d="M49.0516 26.0012H53.3451L43.9651 36.722L55 51.3106H46.3598L39.5924 42.4627L31.849 51.3106H27.5529L37.5858 39.8435L27 26.0012H35.8595L41.9766 34.0886L49.0516 26.0012ZM47.5448 48.7407H49.9238L34.5669 28.4361H32.0138L47.5448 48.7407Z"
				fill="black"
			/>
		</svg>
	);
}
