import * as React from "react";
import Websites from "./Websites";
import AddressInput from "./AddressInput";
import handsMickey from "../assets/Mickey Screaming.png";
import coolhand from "../assets/coolhand.png";
import { useLanguage } from "../LanguageContext";
import transaltions from "../language";

export default function Footer() {
	const { language } = useLanguage();
	return (
		<div className="flex flex-col relative bgFooter ">
			<div className="absolute flex z-10 gap-0 self-end mr-72 max-w-full w-[378px] max-md:mr-2.5 mt-[-170px]">
				<img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8528f482bb6be20b228b45af4118380d56523c079f8ac2285bd90ff709879215?apiKey=095322ce037a477c810ff82f787f6205&" alt="Decorative image" className="grow aspect-[1.96] w-fit" />
				<img
					loading="lazy"
					src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d012085ab9cf8c76a046f8ad22f672870c29c3855ef1b6498f1e77f131e6b55?apiKey=095322ce037a477c810ff82f787f6205&"
					alt="Decorative image"
					className="shrink-0 self-start mt-32 aspect-square w-[41px]"
				/>
			</div>
			<footer className="flex flex-col items-center w-full border-black border-solid border-t-[3px] max-md:max-w-full">
				<div className="flex flex-col items-center w-full max-md:max-w-full mt-10">
					<div className="my-5 max-md:my-10 flex flex-col justify-center align-middle">
						<Websites />
					</div>
					<div className="flex flex-row gap-5 self-center items-center max-md:hidden mb-10">
						<span className="comic-neue-bold text-3xl max-md:text-lg text-zinc-100">{transaltions[language]["trade_on"]}</span>
						<img
							loading="lazy"
							src="https://cdn.builder.io/api/v1/image/assets/TEMP/2655e6dddf631101a9a6d5527ccdeb0bec0d6fcc732cc918072300cb1cdae07b?apiKey=095322ce037a477c810ff82f787f6205&amp;"
							alt="Built on logo"
							class="shrink-0 w-28 aspect-[3.85] fill-white"
						/>
					</div>
					<AddressInput />
					<img loading="lazy" src={handsMickey} alt="Mickey Mouse hands screaming" className="my-10 w-[16vw] max-md:w-[30vw] max-md:mt-5" />
					<div className="w-full flex justify-between px-[8vw] h-[12vh] max-md:h-[10vh]">
						<div className="flex flex-col gap-1 self-center  text-zinc-100 max-md:flex-wrap max-md:max-w-full">
							<h2 className="font-Mickey text-5xl max-md:text-3xl">Moused</h2>
							<div className="flex flex-row gap-3 text-zinc-100">
								<p className="grow my-auto">{transaltions[language]["built_on"]}</p>
								<img
									loading="lazy"
									src="https://cdn.builder.io/api/v1/image/assets/TEMP/2655e6dddf631101a9a6d5527ccdeb0bec0d6fcc732cc918072300cb1cdae07b?apiKey=095322ce037a477c810ff82f787f6205&"
									alt="Built on logo"
									className="shrink-0 w-20 aspect-[3.85] fill-white max-md:w-12"
								/>
							</div>
						</div>
						<div className="flex flex-col self-center">
							<img loading="lazy" src={coolhand} alt="cool hand mickey" className="w-[5vw] max-md:w-[12vw]" />
						</div>
					</div>
					<div className="flex gap-5 self-stretch h-[8vh] text-base text-zinc-100 max-md:flex-wrap max-md:max-w-full justify-center">
						<p className="my-auto">{transaltions[language]["copyright_all_rights_reserved"]}</p>
					</div>
				</div>
			</footer>
		</div>
	);
}
