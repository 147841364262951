import * as React from "react";
import transaltions from "../language";
import { useLanguage } from "../LanguageContext";

export default function AboutUsSection() {
	const { language } = useLanguage();
	return (
		<section className="relative flex flex-col py-20 bgAboutUs border-t-4 border-black h-fit max-md:h-full" id="aboutUs">
			<div className="z-20 pl-[14vw] pr-[6vw] max-md:px-5 max-md:max-w-full h-full">
				<div className="flex gap-20 max-xl:flex-col max-md:gap-0 w-full ">
					<div className="flex flex-col  max-xl:w-full max-md:ml-0 max-md:w-full">
						<div className="flex flex-col grow text-8xl text-black max-md:text-4xl">
							<h2 className="text-9xl font-Mickey leading-none text-white whitespace-nowrap max-lg:text-6xl max-md:text-6xl max-sm:text-5xl text-left crazyaboutUs">{transaltions[language]["about_us"]}</h2>
						</div>
						<div className="flex flex-row w-full max-md:ml-0 max-md:w-full h-full justify-between gap-[5vw]">
							<div className="flex flex-col max-md:mt-2 max-md:max-w-full w-[80%] max-lg:w-full max-md:w-full">
								<AboutUsItem question={transaltions[language]["who_is_bald_mickey_mouse"]} answer={transaltions[language]["moused_isnt_just_a_coin"]} isExpanded={true} />
								<AboutUsItem question={transaltions[language]["what_does_the_community_look_like"]} answer={transaltions[language]["we_recognize_the_absence"]} />
								<AboutUsItem question={transaltions[language]["why"]} answer={<>{transaltions[language]["team"]}</>} />
							</div>
							<div className="h-full">
								<img
									loading="lazy"
									src="https://cdn.builder.io/api/v1/image/assets/TEMP/e936cffb3008b1806bfcc3876bfebdebb52375391955a6d1fb68aa5d0edca861?apiKey=095322ce037a477c810ff82f787f6205&"
									alt="Bald Mickey Mouse"
									className="z-40 aspect-[0.88] max-w-full max-md:ml-2.5 max-md:hidden"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
function AboutUsItem({ question, answer, isExpandedProp }) {
	const [isExpanded, setIsExpanded] = React.useState(isExpandedProp || false);
	const contentRef = React.useRef(null);
	const [contentHeight, setContentHeight] = React.useState(null); // Start with null

	React.useEffect(() => {
		// Function to update the content height
		const updateContentHeight = () => {
			// Ensure we have current content to measure
			if (contentRef.current) {
				const newHeight = isExpanded ? `${contentRef.current.scrollHeight}px` : "0px";
				setContentHeight(newHeight);
			}
		};

		updateContentHeight(); // Call immediately to update height based on initial `isExpanded` value

		// Optional: Add a resize event listener if dynamic content or window resizing could change the height
		window.addEventListener("resize", updateContentHeight);
		return () => window.removeEventListener("resize", updateContentHeight);
	}, [isExpanded]); // Dependency on `isExpanded` to re-calculate when it changes

	const toggleExpansion = () => {
		setIsExpanded((prev) => !prev);
	};

	return (
		<div className="cursor-pointer overflow-hidden justify-between px-10 max-md:px-5 py-6 mt-6 border-black border-solid bg-zinc-100 border-[3px] rounded-[42px]" onClick={toggleExpansion} id="community">
			<div className="flex gap-5">
				<div className="flex flex-col w-[84%] text-left z-10">
					<div className="flex flex-col justify-center grow">
						{question && <h3 className="text-4xl font-Mickey text-black max-md:max-w-full uppercase my-5 whitespace-nowrap max-xl:text-2xl max-lg:text-xl max-md:text-2xl max-sm:text-xl">{question}</h3>}
						<div
							ref={contentRef}
							style={{
								maxHeight: contentHeight, // Use dynamic content height
								overflow: "hidden",
								transition: "all 0.3s ease-in-out",
							}}
						>
							{answer && (
								<p className={`z-1 max-2xl:text-2xl text-[1.5vw] max-xl:text-xl text-black max-md:max-w-full comic-neue-bold w-full ${isExpanded ? "opacity-100" : "opacity-0"}`} style={{ whiteSpace: "pre-line", transition: "all 0.3 ease-in-out" }}>
									{answer}
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col w-[16%] max-md:ml-0 max-md:w-[10%] max-md:my-3 select-none">
					<img
						loading="lazy"
						src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6ced174d3ff3352a915eb83c402a984d50c9f03e44e8e561946ed442bc112f0?apiKey=095322ce037a477c810ff82f787f6205&"
						alt=""
						className={`self-center items-center w-[84px] max-md:w-[42px] max-md:h-auto min-h-4 ${isExpanded ? "rotate-90" : ""}`}
						style={{
							transition: "all 0.3s ease-in-out",
						}}
					/>
				</div>
			</div>
		</div>
	);
}
