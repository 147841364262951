import * as React from "react";
import preSale from "../assets/pre_sale_graphic_003.png";
import supply from "../assets/supply_graphic_003.png";
import mickeyUSA from "../assets/mickeyUSA.png";
import { useLanguage } from "../LanguageContext";
import transaltions from "../language";

function SupplyBreakdownCard() {
	const { language } = useLanguage();
	return (
		<div className="flex flex-col w-5/12 max-lg:w-[80vw] max-xl:w-[70vw] max-xl:self-center max-md:ml-0 max-md:w-full">
			<div className="flex flex-col grow px-14 py-11 w-full border-black border-solid bg-[#E6EFF8] shadow-lg rounded-[50px] max-md:px-5 max-md:mt-0 max-md:pt-0 max-md:max-w-full">
				<img loading="lazy" src={supply} alt="Supply breakdown pie chart" className="self-center max-w-full aspect-square w-[320px] p-10 max-lg:w-[30vw] max-md:w-[250px] max-md:p-8" />
				<div className="vb"></div>

				<h2 className="mt-10 text-4xl leading-7 text-center text-black max-md:mt-10 max-md:max-w-full font-Mickey">{transaltions[language]["supply_breakdown"]}</h2>
				<p className="mt-8 text-2xl font-bold text-left max-md:max-w-full comic-neue-bold">{transaltions[language]["total_supply"]}</p>
				<div className="flex gap-1.5 mt-5 max-md:flex-wrap comic-neue-regular">
					<div className="flex-auto text-lg leading-6  max-md:max-w-full text-left comic-neue-bold">
						<p className="flex flex-row items-center">
							<div className="font-bold shrink-0 bg-orange-300 rounded-full border border-black border-solid h-[11px] stroke-[1px] w-[11px] mr-3" />
							<span>{transaltions[language]["50_percent_of_supply_to_presale"]}</span>
						</p>
						<p className="flex flex-row items-center">
							<div className="shrink-0 bg-orange-300 rounded-full border border-black border-solid h-[11px] stroke-[1px] w-[11px] mr-3" />
							<span>{transaltions[language]["25_percent_of_supply_to_lp"]}</span>
						</p>
						<p className="flex flex-row items-center">
							<div className="shrink-0 bg-orange-300 rounded-full border border-black border-solid h-[11px] stroke-[1px] w-[11px] mr-3" />
							<span>{transaltions[language]["25_percent_marketing_and_cexs"]}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function PresaleBreakdownCard() {
	const { language } = useLanguage();

	return (
		<div className="flex flex-col w-5/12 max-lg:w-[80vw] max-xl:w-[70vw] max-xl:self-center max-md:ml-0 max-md:w-full">
			<div className="flex flex-col grow px-14 py-11 w-full border-black border-solid bg-[#E6EFF8] shadow-lg rounded-[50px] max-md:px-5 max-md:mt-0 max-md:pt-0 max-md:max-w-full">
				<img loading="lazy" src={preSale} alt="Presale breakdown pie chart" className="self-center max-w-full aspect-square w-[320px] p-10 max-lg:w-[30vw] max-md:w-[250px] max-md:p-8" />
				<div className="vb"></div>
				<h2 className="mt-10 text-4xl leading-7 text-black max-md:mt-10 font-Mickey text-center">{transaltions[language]["presale_breakdown"]}</h2>
				<div className="flex gap-1.5 max-md:flex-wrap comic-neue-regular h-full max-md:mt-5">
					<div className="flex-auto text-lg leading-6 max-md:max-w-full text-left my-auto comic-neue-bold mt-5">
						<p className="flex flex-row items-center">
							<div className="shrink-0 bg-orange-300 rounded-full border border-black border-solid h-[11px] stroke-[1px] w-[12px] mr-3" />
							<span>{transaltions[language]["75_percent_of_raise_to_lp"]}</span>
						</p>
						<p className="flex flex-row items-center">
							<div className="shrink-0 bg-orange-300 rounded-full border border-black border-solid h-[11px] stroke-[1px] w-[12px] mr-3" />
							<span className="text-left">{transaltions[language]["25_percent_of_raise_for_opex"]}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default function FourthPage() {
	const { language } = useLanguage();
	return (
		<section className="flex justify-center items-center pb-[20vh] px-14 bg-violet-200 max-md:px-5 max-lg:px-10" id="tokenomics">
			<div className="flex flex-col w-full max-w-[85%] max-lg:max-w-full ">
				<div className="flex flex-col justify-center relative">
					<img loading="lazy" src={mickeyUSA} alt="mickey usa" className="absolute z-10 self-center w-[170px] max-md:w-[90px] h-auto mt-1 max-md:mt-1 max-md:mb-0 max-xl:mt-14" />
					<h1 className="mt-60 max-md:mt-32 text-9xl text-center text-black max-md:max-w-full max-2xl:text-8xl max-md:text-4xl  max-xl:text-6xl font-Mickey z-30 text-nowrap">{transaltions[language]["moused_tokenomics"]}</h1>
				</div>
				<span className="text-black text-center max-md:max-w-full comic-neue-regular text-xl max-md:text-lg max-md:mt-5 max-md:mb-5">{transaltions[language]["the_tokenomics_of_moused"]}</span>
				{/* <React.Suspense fallback={<div>Loading..</div>}>
					<Spline scene="https://prod.spline.design/Lpx52W9HOp8qpKlT/scene.splinecode" />
				</React.Suspense> */}
				<div className="mt-10">
					{/*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/}
					<button className="comic-neue-bold text-center px-10 py-3.5 text-2xl font-bold leading-5 text-white bg-yellow-500 rounded-2xl shadow-md hover:shadow-lg hover:bg-yellow-300 transition-all duration-300 ease-in-out">
						{transaltions[language]["buy_now"]}
					</button>
				</div>
				<div className="flex flex-row gap-[4vw] max-xl:flex-col max-md:mt-10 mt-16 max-md:max-w-full justify-center">
					<SupplyBreakdownCard />
					<PresaleBreakdownCard />
				</div>
			</div>
		</section>
	);
}
