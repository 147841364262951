import * as React from "react";
import Websites from "./Websites";
import { links } from "../utils/links";
import AddressInput from "./AddressInput";
import mickey from "../assets/BALDMICKEY_EDIT 1.png";
import HeaderButtons, { ShareBTN } from "../assets/HeaderButtons";
import original from "../assets/original.png";
import chinese_mouseover from "../assets/chinese_mouseover.png";
import chinese_neutral from "../assets/chinese_neutral.png";
import japanese_mouseover from "../assets/japanese_mouseover.png";
import japanese_neutral from "../assets/japanese_neutral.png";
import korean_mouseover from "../assets/korean_mouseover.png";
import korean_neutral from "../assets/korean_neutral.png";
import transaltions from "../language";
import { useLanguage } from "../LanguageContext";

const MenuLink = ({ children, link, target, className }) => (
	<a className="cursor-pointer" href={link} target={target ? "_blank" : "_self"} rel="noreferrer" className={className}>
		{children}
	</a>
);

const MenuButton = ({ children, link }) => (
	<div
		className="my-auto cursor-pointer justify-center px-3 py-2 bg-yellow-500 border-black border-solid border-[3px] rounded-[30px] font-bold max-md:text-xs hover:bg-yellow-300 transition-all duration-300"
		onClick={() => window.open(link, "_blank")}
	>
		{children}
	</div>
);

const Menu = () => {
	const { language } = useLanguage();
	return (
		<div className="flex gap-3 max-md:w-auto max-sm:gap-2 justify-center py-1 pr-2 pl-4 my-auto border-black border-solid bg-zinc-100 border-[3px] rounded-[30px] whitespace-nowrap">
			<div className="flex gap-4 justify-center my-auto comic-neue-bold text-lg max-md:text-xs max-md:gap-[2vw] ">
				<MenuLink link={"#aboutUs"} className="max-md:hidden">
					{transaltions[language]["about_us"]}
				</MenuLink>
				<MenuLink link={"#tokenomics"}>{transaltions[language]["tokenomics"]}</MenuLink>
				<MenuLink link={links.community} target={true}>
					{transaltions[language]["community"]}
				</MenuLink>
			</div>
			<MenuButton link={links.buy}>{transaltions[language]["buy_now"]}</MenuButton>
		</div>
	);
};

export const Header = () => {
	const [rotationDegree, setRotationDegree] = React.useState(0);

	React.useEffect(() => {
		const handleScroll = () => {
			// Calculate rotation based on the scroll position
			const rotation = window.scrollY * 0.15;
			setRotationDegree(rotation);
		};

		// Add scroll event listener when the component mounts
		window.addEventListener("scroll", handleScroll);

		// Clean up the event listener when the component unmounts
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<header className="flex max-md:flex-row max-md:items-center max-md:gap-2 z-50 justify-between max-md:justify-center text-base text-black w-full fixed max-w-full px-[3vw] mt-3">
			<img loading="lazy" src="https://i.imgur.com/0cAF5iP.png" alt="" className=" mt-2 w-[80px] h-[80px] max-md:hidden" style={{ transform: `rotate(${rotationDegree}deg)` }} />
			<ShareBTN shownOnMobile={true} />
			<Menu />
			<HeaderButtons />
		</header>
	);
};

export default function MainPage() {
	const { language } = useLanguage();

	const mainIMG = {
		EN: original,
		CN: chinese_neutral,
		JP: japanese_neutral,
		KR: korean_neutral,
	};

	const mouseoverIMG = {
		EN: original,
		CN: chinese_mouseover,
		JP: japanese_mouseover,
		KR: korean_mouseover,
	};

	return (
		<div className="flex flex-col pb-5 w-full bg-yellow-300 border-b-[3px] border-x-[3px] border-black border-solid  max-md:max-w-full" style={{ borderBottomLeftRadius: 60, borderBottomRightRadius: 60 }}>
			<div className="flex flex-col pb-5 w-full bg-red-500 border-b-[3px] border-x-[3px] border-black border-solid  max-md:max-w-full" style={{ borderBottomLeftRadius: 60, borderBottomRightRadius: 60 }}>
				<div className="flex gap-5 max-md:gap-2 max-sm:px-0 w-full bg-blue-600  border-b-[3px] border-x-[3px] border-black border-solid max-md:flex-wrap max-md:max-w-full h-[94vh]" style={{ borderBottomLeftRadius: 60, borderBottomRightRadius: 60 }}>
					<div className="flex flex-col mt-3 w-[100vw] max-md:w-[100%]">
						<section className="mt-28 w-full flex flex-col self-center h-full justify-evenly max-md:h-[75vh] px-[8vw] max-md:px-[3vw] max-md:mt-16">
							<img loading="lazy" src={mickey} alt="" className="self-center w-full max-w-[35vw] max-md:max-w-[70%] max-md:mt-8 max-md:mb-0" />
							<main className="self-center w-full max-w-[1242px] comic-neue-regular">
								<div className="flex flex-row gap-[5vw] max-xl:gap-[0vw] max-lg:gap-[0vw] w-full max-md:flex-col">
									<span className="self-stretch my-auto text-xl text-zinc-100 max-md:mt-0 w-full max-md:hidden font-bold">{transaltions[language]["with_nothing_but_a_bald_head_and_a_dream"]}</span>
									<section className="flex flex-col w-full justify-center self-center align-middle items-center">
										<img
											loading="lazy"
											src={mainIMG[language]}
											alt={"mickey bald lol"}
											className={`aspect-[1] max-md:mt-5 max-w-[17vw] max-sm:my-0 max-sm:max-w-[45vw] shakingTilt max-md:max-w-[34vw]`}
											onMouseEnter={(e) => (e.currentTarget.src = mouseoverIMG[language])}
											onMouseLeave={(e) => (e.currentTarget.src = mainIMG[language])}
										/>
									</section>
									<span className="self-stretch my-auto text-xl text-zinc-100 max-md:mt-0 w-full max-md:hidden font-bold">{transaltions[language]["getting_moused_became_a_badge_of_honour"]}</span>
								</div>
							</main>
							<Websites />
							<div className="w-[100%] flex flex-row items-center self-center justify-center">
								<AddressInput />
							</div>
							<footer className="flex gap-3 m-3.5 max-2xl:mb-5  self-end text-base text-zinc-100 mr-[6vw] max-md:self-center">
								<div className="grow my-auto ">{transaltions[language]["built_on_copyright"]}</div>
								<img
									loading="lazy"
									src="https://cdn.builder.io/api/v1/image/assets/TEMP/2655e6dddf631101a9a6d5527ccdeb0bec0d6fcc732cc918072300cb1cdae07b?apiKey=095322ce037a477c810ff82f787f6205&"
									alt=""
									className="shrink-0 w-20 aspect-[3.85] fill-white"
								/>
							</footer>
						</section>
					</div>
				</div>
			</div>
		</div>
	);
}
