import { X } from "../assets/twitter";
import { Telegram } from "../assets/telegram";
import { Gecko } from "../assets/coingecko";
import { Etherscan } from "../assets/etherscan";
import { Dextools } from "../assets/Mexc";
import { links } from "../utils/links";

export default function Websites() {
	return (
		<div className="flex flex-col z-10 text-zinc-100 max-md:max-w-full self-center my-[2vh]">
			<div className="flex gap-4 justify-center max-md:flex-wrap max-md:justify-center max-md:gap-2">
				{/* on hover increase size*/}
				<a href={links.x} target="_blank" rel="noopener noreferrer">
					<X className="hover:scale-110 transition-all duration-300" />
				</a>
				<a href={links.telegram} target="_blank" rel="noopener noreferrer">
					<Telegram className="hover:scale-110 transition-all duration-300" />
				</a>
				<a href={links.gecko} target="_blank" rel="noopener noreferrer">
					<Gecko className="hover:scale-110 transition-all duration-300" />
				</a>
				<a href={links.etherscan} target="_blank" rel="noopener noreferrer">
					<Etherscan className="hover:scale-110 transition-all duration-300" />
				</a>
				<a href={links.dextools} target="_blank" rel="noopener noreferrer">
					<Dextools className="hover:scale-110 transition-all duration-300" />
				</a>
			</div>
		</div>
	);
}
