import React, { createContext, useContext, useState } from "react";

// Create a context with a default value (optional)
const LanguageContext = createContext();

// Language provider component
export const LanguageProvider = ({ children }) => {
	const [language, setLanguage] = useState("EN"); // Default language

	const switchLanguage = (lang) => setLanguage(lang);

	return <LanguageContext.Provider value={{ language, switchLanguage }}>{children}</LanguageContext.Provider>;
};

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);
